import axios from "axios"
import { backendUrl } from "../../../../apiUtils/urls"
import { useGlobalNotification } from "../../../../utils/Notification"
import { useAuditContext } from "../../auditContext"
import { saveTable as saveTableRequest } from "../../../../apiUtils/requests";

export function useSaveTable(updatedFields) {
    const notification = useGlobalNotification()
    const { auditId, setAwaitedResponsesCount } = useAuditContext()

    async function saveTable() {

        if (!updatedFields.current?.length) {
            notification.setMsg("Няма нищо за запазване")
            notification.setIsOpen(true)
            return
        }

        console.log("Saving ", JSON.parse(JSON.stringify(updatedFields.current)))

        try {
            const feildsByTableId = updatedFields.current.reduce((map, current) => {
                const key = current.tableId
                return {
                    ...map,
                    [key]: [...(map[key] || []), current.cell]
                }
            }, {})

            setAwaitedResponsesCount(c => c + 1)
            const resp = await Promise.all(Object.entries(feildsByTableId).map(async ([tableId, cells]) => {
                for (const cell of cells) {
                    if (cell.files) {
                        for (const file of cell.files) {
                            const fd = new FormData();

                            fd.append('file', file);

                            axios.post(`${backendUrl}/table/file?cellId=${cell.cellId}&auditId=${auditId}`, fd)
                        }
                    }
                }

                return saveTableRequest(Number(auditId), tableId, cells)
            }))
            updatedFields.current = []
        }
        catch (e) {
            console.log(e)
            notification.setMsg("Грешка при запазването на таблица")
            return
        }
        finally {
            notification.setIsOpen(true)
            setAwaitedResponsesCount(c => c - 1)
        }

        notification.setMsg("Таблицата е запазена")
    }

    return saveTable
}