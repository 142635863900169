import { useEffect, useState } from "react";
import "./Area.scss";
import { useTableContext } from "../../ViewAudit/tableContext";
import OptimizedInput from "../../../../utils/OptimizedInput";

const Area = ({ elementData }) => {
  const [hasErrors, setHasErrors] = useState(false);
  const elementClasses = "textarea-wrapper " + elementData.styleClass;

  const {updateCell} = useTableContext();

  function inputHandler(newValue) {
    updateCell({
      id: elementData.id,
      value: newValue
    })
    validateData(newValue);
  }

  function validateData(value) {
    let valid = false;
    if (!elementData.required) {
      valid = true;
    } else {
      if (value === "") {
        valid = false;
      } else {
        valid = true;
      }
    }

    // if (valid) {
    //     setHasErrors(false);
    // } else {
    //     setHasErrors(true);
    // }

    return valid;

    // const regExp = new RegExp(elementData.validation);
    // if(!regExp.test(value)) {
    //     return false
    // } else {
    //     return true;
    // }
  }

  return (
    <div
      className={
        "input-wrapper" + (elementData.label === "" || elementData.label === " " ? "transparent" : "textarea-wrapper")
      }
    >
      <label>
        {elementData.label}
        <OptimizedInput
          Component="textarea"
          name={elementData.name}
          defaultValue={elementData.value}
          onChange={inputHandler}
        ></OptimizedInput>
      </label>
      {hasErrors ? (
        <p className="error-msg">{elementData.validationError}</p>
      ) : null}
    </div>
  );
};

export default Area;
