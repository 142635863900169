import "./Input.scss";
import { useState } from "react";
import { Box } from "@mui/system";
import { useTableContext } from "../../ViewAudit/tableContext";
import { Tooltip } from "@mui/material";
import OptimizedInput from "../../../../utils/OptimizedInput";
import { TextField } from "./Text";
import { TextField as TextFieldInput } from "@mui/material"

function PlaceholderWidthInput({ inputElement, placeholder }) {
  return <div className="placeholder-wrapper" style={{ "--label": '"' + (placeholder || " ") + '"' }} data-label={placeholder || " "}>
    {inputElement}
  </div>
}

const Input = ({ elementData }) => {
  const [hasErrors, setHasErrors] = useState(false);
  const id = elementData.id;
  const elementClasses = elementData.styleClass
    ? elementData.styleClass
    : "input-wrapper";

  const { updateCell } = useTableContext()

  function inputHandler(newValue) {

    if (newValue === elementData.value) {
      return
    }

    updateCell({
      id: elementData.id,
      value: newValue
    })

    validateData(newValue);
  }

  function validateData(value) {
    let valid = false;
    value = value?.trim();

    if (!elementData.required) {
      valid = true;
    } else {
      if (value === "") {
        valid = false;
      } else {
        valid = true;
      }
    }

    return valid;
  }

  const isEditable = elementData.editable

  const isType2 = elementClasses === "input-type-2"

  const placeholder = elementData.label === "empty;" ? "" : elementData.label

  if (!isEditable) {
    if(!elementData.label || !elementData.value){
      return <TextField elementData={{label: elementData.value || elementData.label}}/>
    }

    return <TextFieldInput
      disabled
      value={elementData.value}
      label={elementData.label}
      variant="outlined" />
  }

  const input = <label>
    {placeholder}
    <OptimizedInput
      Component={TextFieldInput}
      onChange={inputHandler}
      variant="outlined"
      type="input"
      size="small"
      multiline
      value={elementData.value}
      id={elementData.key}
    />
  </label>

  const label = elementData.label === "empty;" ? "" : elementData.label

  return <Tooltip title={elementData.tooltip}>
    <div key={id} className={elementClasses + " color-excel"}>
      {isType2 ? <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
        }}
      >
        <p>{label}</p>
        {input}
      </Box>
        :
        input
      }

      {hasErrors ? (
        <p className="error-msg">{elementData.validationError}</p>
      ) : null}
    </div>
  </Tooltip>
};

export default Input;
