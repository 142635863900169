import React from "react";
import "./index.scss";
import { getCompanyEGO } from "../../apiUtils/requests";
import { TableContextProvider } from "../Audits/ViewAudit/tableContext";
import { auditContext } from "../Audits/auditContext"
import { MainStageContentWithoutContext } from "../Audits/ViewAudit/MainStageContent";

export const AnnualReport = () => {
  return <auditContext.Provider value={{ auditId: 0 }}>
    <TableContextProvider fetchTable={getCompanyEGO}>
      <MainStageContentWithoutContext 
      currentMenuOptionName="ЕГО" 
      className="annual-report-page"
      noCompanyDetails />
    </TableContextProvider>
  </auditContext.Provider>
};
