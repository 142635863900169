import { useEffect, useState } from 'react';
import './Hint.scss';
import { Typography } from '@mui/material';

const Hint = ({ elementData }) => {
    const [hasErrors, setHasErrors] = useState(false);
    const elementClasses = 'hint-wrapper ' + elementData.styleClass;
    
    // useEffect(() => {
    //     setFieldsSubmitData(oldState => {
    //         const arrCopy = [...oldState];
    //         if (arrCopy.some((e) => e[elementData.key] !== undefined)) {
    //             return arrCopy;
    //         } else {
    //             arrCopy.push({ key:elementData.key, value:elementData.value});
    //             return arrCopy;
    //         }
    //     })
    //     setInputValue(
    //         elementData.value !== undefined && elementData.value !== null
    //             ? elementData.value
    //             : ''
    //     );
    // }, []);

    // function inputHandler(ev) {
    //     const newValue = ev.target.value;
    //     setFieldsSubmitData((oldState) => {
    //         const arrCopy = [...oldState];
    //         const i = findIndexOfField(elementData.key, oldState);
    //         arrCopy[i] = { key: elementData.key, value: newValue};
    //         return arrCopy;
    //     });
    //     setInputValue(newValue);
    //     // validateData(newValue);
    // }

    function validateData(value) {
        let valid = false;
        if (!elementData.required) {
            valid = true;
        } else {
            if (value === '') {
                valid = false;
            } else {
                valid = true;
            }
        }

        // if (valid) {
        //     setHasErrors(false);
        // } else {
        //     setHasErrors(true);
        // }

        return valid;

        // const regExp = new RegExp(elementData.validation);
        // if(!regExp.test(value)) {
        //     return false
        // } else {
        //     return true;
        // }
    }

    // function blurHandler() {
    //     validateData(inputValue);
    // }

    return (
        <div className={elementClasses}>
            <Typography
                sx={{fontFamily:'Segoe UI'}}
                className='title2'
                name={elementData.name}
                id="elementData.id"
                rows="1"
                // onBlur={blurHandler}
                // onChange={inputHandler}
            >{elementData.label} </Typography>
            {hasErrors ? (
                <p className="error-msg">{elementData.validationError}</p>
            ) : null}
        </div>
    );
};

export default Hint;
