import { useCallback, useRef } from "react";
import { updateTableCell } from "../../../../apiUtils/requests";
import { useAuditContext } from "../../auditContext";
import { useGlobalNotification } from "../../../../utils/Notification";
import { backendUrl } from "../../../../apiUtils/urls";
import axios from "axios";

export function useUpdateCell(setRows, table) {
    const auditContextValue = useAuditContext()
    const notification = useGlobalNotification()
    const updatedFields = useRef([])

    async function updateField(cell) {
        if (table.savable) {
            updateFieldWithoutFormulae(cell)
            return
        }

        let updateCellResp
        auditContextValue.setAwaitedStepRequestsCount(c => c + 1)
        try {
            if (cell.files) {

                for (const file of cell.files) {
                    const fd = new FormData();

                    fd.append('file', file);

                    axios.post(`${backendUrl}/table/file?cellId=${cell.id}&auditId=${auditContextValue.auditId}`, fd)
                }

                updateCellResp = { data: { modifiedCells: [] } }
            }
            else {
                updateCellResp = await updateTableCell(Number(auditContextValue.auditId), cell.id, cell.value)
            }
            notification.setMsg("Таблицата е запазена")
        }
        catch (e) {
            notification.setMsg("Failed to update the cell")
            notification.setSeverity("error")
        }
        finally {
            notification.setIsOpen(true)
        }
        auditContextValue.setAwaitedStepRequestsCount(c => c - 1)



        const modifiedCells = updateCellResp.data?.modifiedCells

        if (!modifiedCells) {
            console.error("Failed to update cell")
            return
        }

        let cellsToUpdateLeft = [
            ...modifiedCells,
            cell
        ]

        function getUpdatedData(rows) {
            const res = rows?.map((row) => {
                if (!row.isLoaded) {
                    return row
                }

                // If row is not present, the updated cell can be in any row. Row id is needed for optimization only
                const updatedCells = cellsToUpdateLeft.filter(cellToUpdate => !cellToUpdate.row || (cellToUpdate.row === row.id))

                if (!updatedCells.length) {
                    return row
                }

                const cells = row.cells?.map(cell => {
                    const cellUpdateIndex = updatedCells.findIndex(updated => updated.id === cell.id)

                    if (cellUpdateIndex === -1) {
                        return cell
                    }

                    const [cellUpdate] = updatedCells.splice(cellUpdateIndex, 1)

                    return {
                        ...cell,
                        value: cellUpdate.value
                    }
                })

                cellsToUpdateLeft = cellsToUpdateLeft.filter(cellToUpdate => cellToUpdate.row !== row.id)

                return {
                    ...row,
                    cells
                }
            })

            console.log("Update rows. Was: ", rows, "now: ", res)

            return res
        }

        setRows(getUpdatedData)
    }


    function updateFieldWithoutFormulae(updatedCell) {
        function getUpdatedData(rows, tableId) {
            let isFound = false
            console.log("updating ", JSON.parse(JSON.stringify(rows)), tableId)

            const res = rows?.map((row) => {
                if (isFound || !row.isLoaded) {
                    return row
                }

                const cells = row.cells?.map(cell => {
                    if (isFound) {
                        return cell
                    }

                    if (Number(cell.id) !== Number(updatedCell.id)) {
                        if (cell.rows) {
                            const innerRowsUpdate = getUpdatedData(cell.rows, cell.label)
                            isFound = innerRowsUpdate.isFound
                            if (!isFound) {
                                return cell
                            }

                            return {
                                ...cell,
                                rows: innerRowsUpdate.rows
                            }
                        }

                        return cell
                    }

                    isFound = true

                    const updatedCellIndex = updatedFields.current.findIndex(f => f.id === cell.id)


                    const cellUpdateData = {
                        tableId,
                        cell: {
                            cellId: cell.id,
                            value: updatedCell.files?.length ? null : updatedCell.value,
                            files: updatedCell.files
                        }
                    }

                    if (updatedCellIndex === -1) {
                        updatedFields.current.push(cellUpdateData)
                    }

                    updatedFields.current[updatedCellIndex] = cellUpdateData

                    return {
                        ...cell,
                        ...updatedCell
                    }
                })

                if (!isFound) {
                    return row
                }

                return {
                    ...row,
                    cells
                }
            })

            console.log("Updated cell: ", updatedCell, " rows were: ", rows, "now: ", res)
            return {
                rows: res,
                isFound
            }
        }


        setRows(rows => getUpdatedData(rows, table?.id).rows)
    }

    return {
        updateCell: useCallback(updateField, [setRows, table]),
        updatedCells: updatedFields
    }
}
