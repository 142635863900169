import { CircularProgress } from "@mui/material";
import { LoadingIndicator } from "react-admin";
import { Link, useLocation, useMatch } from "react-router-dom";

function LinkWithActiveState({ baseUrl, id, name }) {
  const selected = useMatch(`/${baseUrl}/${id}`)

  return <li className={"list-item" + (selected ? " selected" : "")}>
    <Link className={"dropdown-link"} to={`/${baseUrl}/${id}`}>{name}</Link>
  </li>

}

const DropDown = ({ baseUrl, submenus, isOpen }) => {

  if (!submenus) {
    return isOpen && <CircularProgress style={{ margin: "0 auto" }} color="secondary" />
  }

  return (
    <ul className={`dropdown ${isOpen ? "show" : ""}`}>
      {submenus.map((submenu) => (
        <LinkWithActiveState
          key={submenu.id}
          {...submenu}
          baseUrl={baseUrl} />
      ))}
    </ul>
  );
};

export default DropDown;