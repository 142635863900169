// import { Button, CheckBox, Popup } from 'devextreme-react';
import { useEffect, useState } from 'react';
import './Consents.scss';
// import * as directionService from '../../../../../apiUtils/directions';
import { useTableContext } from '../../ViewAudit/tableContext';

const Consents = ({ elementData }) => {
    const [checkboxValue, setCheckboxValue] = useState(null);
    const [hasErrors, setHasErrors] = useState(false);
    const elementClasses = 'consent-wrapper ' + elementData.styleClass;

    const {updateCell} = useTableContext()

    const checkHandler = (ev) => {
        const newValue = ev.target.checked;
        updateCell({
            id: elementData.id,
            value: newValue
        })
        setCheckboxValue(newValue);
        // validateData(newValue);
    };

    function validateData(value) {
        let valid = false;
        if (!elementData.required) {
            valid = true;
        } else {
            if (value === false) {
                valid = false;
            } else {
                valid = true;
            }
        }

        // if (valid) {
        //     setHasErrors(false);
        // } else {
        //     setHasErrors(true);
        // }

        return valid;
    }

    return (
        <div className={elementClasses}>
            <div>
                <label htmlFor={elementData.key}>{elementData.label}</label>
                <input
                    type="checkbox"
                    id={elementData.key}
                    onChange={checkHandler}
                />
            </div>
            {hasErrors ? (
                <p className="error-msg">{elementData.validationError}</p>
            ) : null}
        </div>
    );
};

export default Consents;
