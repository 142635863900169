import * as React from "react";
import { useRecordContext } from 'react-admin';
import { Button, Link, Typography } from '@mui/material';
import { Box } from "@mui/system";
import AdfScannerIcon from '@mui/icons-material/AdfScanner';
import Popup from "reactjs-popup";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';


const MyDocField = ({ source }) => {

  const [open, setOpen] = React.useState(false);
  const closeModal = () => setOpen(false);
  const record = useRecordContext();

  return record ? (
    <div>
      <Box sx={{ display: "flex", justifyContent: "center", padding: "5px" }}>
        <Button startIcon={<AdfScannerIcon color='disabled' />} onClick={() => setOpen((o) => !o)}></Button>
      </Box>

      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        <div className="popUp-content">
          <Typography variant="h6" sx={{ display: "flex", alignSelf: "center" }}>Документи за одит на `"{record.name}"</Typography>
          <Box
            sx={{
              gap: "1%",
              display: "flex",
              justifyContent: "left",
              padding: "10px",
              marginTop: "10px",
            }}>
            <Typography>1. Договор за одит</Typography>
            <Link href={record.contract} sx={{ textDecoration: 'none' }} target="_blank">
              <FileDownloadOutlinedIcon />
            </Link>
          </Box>
          <Box
            sx={{
              gap: "1%",
              display: "flex",
              justifyContent: "left",
              padding: "10px",
              marginTop: "10px",
            }}>
            <Typography>2. Писмо за поемане на одиторски ангажимент</Typography>
            <Link href={record.letter} sx={{ textDecoration: 'none' }} target="_blank">
              <FileDownloadOutlinedIcon />
            </Link>
          </Box>
        </div>
      </Popup>
    </div>
  ) : null;

}

export default MyDocField;