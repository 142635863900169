import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  Backdrop,
  CircularProgress,
  Alert,
  Grid,
  LinearProgress,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import { auditContext, useAuditContext } from "../auditContext";
import { getAuditById, getStageTest as getStageTests, getTestTables } from "../../../apiUtils/requests";
import { useStagesContext } from "../../../utils/stagesContext"

import "./index.scss";
import { StageContent } from "./Stage";

export default function AuditPageWrapper(props) {
  const urlId = useParams().auditId;
  const auditId = props.auditId || urlId
  const [awaitedResponsesCount, setAwaitedResponsesCount] = useState(0);

  return <auditContext.Provider value={{ setAwaitedResponsesCount, auditId }}>
    <AuditPage />

    <Backdrop
      sx={{
        color: "#fff",
        position: "absolute",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={!!awaitedResponsesCount}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

  </auditContext.Provider>
}


function TopNavigation({ auditQuery, stage }) {
  const auditNameRef = useRef()
  const [nameContainerWidth, setNameContainerWidth] = useState()

  useEffect(() => {
    const rect = auditNameRef.current.getBoundingClientRect()
    setNameContainerWidth(rect.width)
  }, [auditQuery.data?.name])

  return <Typography variant="h6" sx={{ padding: "5px", marginBottom: "10px" }}>
    Одити /
    <span style={{
      display: "inline-block",
      width: nameContainerWidth,
      transition: "width 0.5s",
      overflow: "hidden",
      verticalAlign: "bottom"
    }}>
      <span ref={auditNameRef} style={{ whiteSpace: "pre" }}>
        {" "}
        {auditQuery.data?.name ?
          `"${auditQuery.data?.name}"` :
          <LinearProgress style={{ display: "inline-block", width: "100px" }} />}
        {" "}
      </span>
    </span>
    / {stage?.name}
  </Typography>
}

function AuditPage({ }) {
  const [openLinkIndex, setOpenLinkIndex] = useState(0)

  const [awaitedStepRequestsCount, setAwaitedStepRequestsCount] = useState(0)

  const [links, setLinks] = useState()

  const auditContextValue = useAuditContext()
  const { stages } = useStagesContext()

  const { stageId } = useParams()

  const auditQuery = useQuery(["audit", auditContextValue.auditId], async ({ queryKey }) => {
    const auditId = queryKey[1]
    const resp = await getAuditById(auditId)
    return resp.data
  })

  // console.log('stage', stage);

  const stage = useMemo(() => stages?.find(stage => stage.id == stageId), [stages, stageId])

  const contextValue = {
    ...auditContextValue,
    awaitedStepRequestsCount,
    setAwaitedStepRequestsCount
  };

  useEffect(() => {
    auditContextValue.setAwaitedResponsesCount(c => c + 1)
    getStageTests(stageId).then(resp => {
      auditContextValue.setAwaitedResponsesCount(c => c - 1)
      if (!resp.data) {
        return
      }

      setLinks(resp.data.stageTests)
      setOpenLinkIndex(0)
    })
  }, [stageId])


  return <auditContext.Provider value={contextValue}>
    <TopNavigation
      auditQuery={auditQuery}
      stage={stage} />

    <StageContent
      stageName={stage?.name}
      links={links}
      openLinkIndex={openLinkIndex}
      setOpenLinkIndex={setOpenLinkIndex} />
  </auditContext.Provider>
}