import { Box, LinearProgress } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import {
  Datagrid,
  DateField,
  FilterForm,
  List,
  Pagination,
  RadioButtonGroupInput,
  ResourceContextProvider,
  SelectArrayInput,
  SelectInput,
  TextField,
  TextInput,
  useListContext,
} from "react-admin";
import AuditNameLink from "./AuditNameLink";
import MyDocField from "./DocsCustomField";
import { getAllYears } from "../../apiUtils";
import { useYears } from "../../utils/yearsContext";


function ListToolbar() {
  const listContextValue = useListContext()

  const years = useYears().getYears()

  const formattedYears = useMemo(() => years ?
    [...new Set(years)]
      .filter(year => year)
      .map(year => ({ id: year, name: year }))
    : null, [years])

  const auditFilters = [
    <TextInput
      label="Търси"
      variant="outlined"
      source="search"
      alwaysOn
      sx={{ marginLeft: "5px" }}
    />,
    <SelectInput
      variant="outlined"
      label="Година на одит"
      alwaysOn
      source="auditYear"
      emptyText="Всичките години"
      choices={formattedYears || []}
    />
  ];

  return <Box>
    <Stack direction="row" justifyContent="space-between">
      <FilterForm filters={auditFilters} />
    </Stack>
  </Box>
}

const PostPagination = () => <Pagination rowsPerPageOptions={[5, 10, 15]} />;

function LoadingIndication() {
  const listContext = useListContext()

  console.log(Date.now(), listContext)

  return <div style={{ height: "2px" }}>
    {listContext.isFetching ? <LinearProgress /> : null}
  </div>
}

export default function AuditsTable() {
  return <ResourceContextProvider value="audit/all">
    <List
      sx={{
        width: "100%",
        "& .css-bhp9pd-MuiPaper-root-MuiCard-root": {
          borderRadius: "none",
          boxShadow: "none",
        },
      }}
      size={7}
      exporter={false}
      pagination={<PostPagination />}
      actions={null}

    >
      <div>
        <ListToolbar />
        <LoadingIndication />
        <Datagrid
          sx={{
            "& .RaDatagrid-tableWrapper": {
              boxShadow: "none",
            },
            "& .RaDatagrid-rowCell": {
              padding: "4px 16px",
            },
          }}
          className="classes"
          bulkActionButtons={false}
        >
          <AuditNameLink label="Име на одит" />
          <TextField source="eik" label="ЕИК" sx={{ textAlign: "center" }} />
          <TextField source="auditor" label="Отговорен одитор" />
          <DateField
            source="date"
            label="Дата на създаване"
            locales="bg-BG"
            textAlign="left"
          />
          <MyDocField label="Документи" />
        </Datagrid>
      </div>
    </List>
  </ResourceContextProvider>

};
