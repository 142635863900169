import { Button, CircularProgress, Grid, LinearProgress, Stack, Tab, Tabs } from "@mui/material";
import Section from "../fields/Types/Section"
import isFieldAButton from "../isFieldAButton";
import { useEffect, useMemo, useRef } from "react";
import { useAuditContext } from "../auditContext";
import { createTableRow } from "../../../apiUtils/requests";
import InfiniteScroll from "react-infinite-scroll-component"
import { useTableContext } from "./tableContext";
import { Add } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import FullScreenIcon from "@mui/icons-material/Fullscreen"
import ExitFullScreenIcon from "@mui/icons-material/FullscreenExit"

// const buttonDisplayTypes = {
//   FORWARD: "FORWARD",
//   BACK: "BACK",
//   TAB: "TAB",
//   OTHER: "OTHER"
// }

export function getButtonsFromFields(fields) {
  return fields?.flatMap(field => field?.fields?.filter(isFieldAButton) || (isFieldAButton(field) ? field : []))
}

export function useButtonsByType(fields) {
  function getButtonsByType() {
    const map = {
      forward: [],
      backward: [],
      other: [],
      tabs: []
    }

    if (!fields) {
      return map
    }

    const buttons = getButtonsFromFields(fields)

    for (const button of buttons) {
      if (!button) {
        continue
      }

      if (button.label === "Напред") {
        map.forward.push(button)
        continue
      }

      if (button.label === "Назад") {
        map.backward.push(button)
        continue
      }

      if (button.notes?.includes("tab")) {
        map.tabs.push(button)
        continue
      }

      map.other.push(button)
    }

    return map
  }

  return useMemo(getButtonsByType, [fields])
}

// function Buttons({ buttons }) {
//   return buttons?.map(field => <Flow key={field.key} elementData={field} />)
// }

function SectionButtons({ }) {
  const { rows, selectedTable } = useTableContext()

  // const buttonsByType = useButtonsByType(rows)

  const showAddRowBtn = selectedTable?.structureType === "BASE_ROWS"

  return <Stack gap={1} className="step-buttons">
    {/* <Grid container>
      <Grid>
        <Buttons buttons={buttonsByType.backward} />
      </Grid>
      <Grid marginLeft="auto">
        <Buttons buttons={buttonsByType.forward} />
      </Grid>
    </Grid>

    <Stack direction="row" gap={1}>
      <Buttons buttons={buttonsByType.other} />
    </Stack> */}

    {showAddRowBtn && <AddRowButton />}
  </Stack>
}

function AddRowButton() {
  const { selectedTable, addRow } = useTableContext();
  const { auditId } = useAuditContext()

  async function onAddRow() {
    const resp = await createTableRow(auditId, selectedTable?.id);
    const newRow = resp.data
    if (!newRow) {
      console.log("Failed to create row")
      return
    }

    addRow({
      ...newRow,
      id: newRow.row
    })
  }

  return <Button
    variant="contained"
    startIcon={<Add />}
    onClick={onAddRow}
    size="large"
    color="primary"
    style={{
      position: "fixed",
      bottom: "3em",
      right: "3em"
    }}>
    Ред
  </Button>
}

function FullscreenButton({ fullScreenHandle }) {
  return <IconButton
    onClick={fullScreenHandle.active ? fullScreenHandle.exit : fullScreenHandle.enter}
    size="large"
    style={{ margin: "auto", marginRight: 0 }}
    id="full-screen-button">
    {fullScreenHandle.active ? <ExitFullScreenIcon /> : <FullScreenIcon />}
  </IconButton>
}



function GeneralStep({ fullScreenHandle }) {
  const { tables, awaitedStepRequestsCount, awaitedRequestsCount } = useAuditContext()

  const { selectedTabIndex, setSelectedTabIndex } = useTableContext()

  useEffect(() => {
    tables && setSelectedTabIndex(0)
  }, [tables])

  return <div className="step">

    <Stack direction="row" className="table-actions">
      {tables?.length > 1 ? <Tabs
        sx={{
          ".MuiTabs-flexContainer": {
            paddingBottom: "10px"
          }
        }}
        variant="scrollable"
        visibleScrollbar
        value={selectedTabIndex}
        onChange={(_, value) => setSelectedTabIndex(value)}>
        {tables.map((table, index) => <Tab

          value={index}
          label={table.name}
          key={table.id} />
        )}
      </Tabs> : null}

      <FullscreenButton fullScreenHandle={fullScreenHandle} />
    </Stack>

    <div style={{ height: "10px" }}>
      {!awaitedStepRequestsCount || awaitedRequestsCount ? null :
        <LinearProgress />}
    </div>

    <div className="step-content">
      <StageTable />
    </div>
  </div >
}


function StageTable({ }) {
  const { rows, totalRowsCount, fetchNextRows } = useTableContext()
  const loadedRowsCount = rows?.length || 0

  const scrollContainerRef = useRef()

  function onRequestNextRows() {
    console.log("Request rows due to scroll")
    fetchNextRows(10)
  }

  useEffect(() => {
    console.log("Loaded rows count:", loadedRowsCount)
    if (!loadedRowsCount) {
      return
    }

    scrollContainerRef.current.onScrollListener({ target: scrollContainerRef.current.el })
  }, [loadedRowsCount])

  // console.log("Render table. Rows: ", rows, " loaded: ", loadedRowsCount)

  const hasMore = totalRowsCount > loadedRowsCount

  return <>
    <InfiniteScroll
      dataLength={loadedRowsCount}
      next={onRequestNextRows}
      hasMore={hasMore}
      loader={<Grid container>
        <CircularProgress style={{ margin: "auto" }} />
      </Grid>}
      height="100%"
      style={{ height: "100%" }}
      ref={scrollContainerRef}
    >
      <table className="stage-table">
        <tbody>
          {rows?.map((field, i) => <Section key={i} elementData={field} />)}
        </tbody>
      </table>
    </InfiniteScroll>

    <SectionButtons />
  </>
}


export default GeneralStep;
