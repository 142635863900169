import { Box, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import {
  Datagrid,
  FilterForm,
  List,
  ResourceContextProvider,
  TextField,
  TextInput,
} from "react-admin";
import MyUrlField from "./UrlCustomField";
import { Link } from "react-router-dom";
import AdfScannerIcon from "@mui/icons-material/AdfScanner";

const libraryFilters = [
  <TextInput
    label="Търси"
    variant="outlined"
    source="search"
    alwaysOn
    sx={{ marginLeft: "5px" }}
  />,
];

const ListToolbar = () => (
  <Box>
    <Stack direction="row" justifyContent="space-between">
      <FilterForm filters={libraryFilters} />
    </Stack>
  </Box>
);

export const Library = () => {
  return (
    <Paper
      sx={{ width: "90%", alignSelf: "center", margin: "10px" }}
      elevation={0}
    >
      <Typography sx={{ fontSize: "18px", margin: "10px 0px 10px 20px" }}>
        Начало / Библиотека
      </Typography>
      <Box
        sx={{
          margin: "10px 0px 10px 20px",
          display: "flex",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <AdfScannerIcon color="disabled" fontSize="large" />
        <Link to="/library/my-docs" className="exLink">
          {" "}
          Моите документи
        </Link>
      </Box>
      <ResourceContextProvider value={"document/all"}>
          <List
            sx={{
              width: "100%",
              alignSelf: "center",
              "& .css-bhp9pd-MuiPaper-root-MuiCard-root": {
                borderRadius: "none",
                boxShadow: "none",
              },
            }}
            exporter={false}
            actions={null}
            pagination={false}
          >
            <ListToolbar />
            <Datagrid bulkActionButtons={false}>
              <TextField source="name" label="Име на документ" />
              <TextField source="" label="Вид" />
              <TextField source="description" label="Описание" />
              <MyUrlField target="_blank" source="filename" label="Преглед" />
            </Datagrid>
          </List>
      </ResourceContextProvider>
    </Paper>
  );
};
