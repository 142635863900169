import { Button, ButtonGroup } from "@mui/material";
import ChevronRight from "@mui/icons-material/ChevronRight"
import { useState } from "react";

import "./index.scss"

function GroupButton({ value, buttonValue, setIsMenuOpen, onChange }) {
    return <Button
        onClick={() => { setIsMenuOpen(false); onChange(buttonValue) }}
        variant={value === buttonValue ? "contained" : "outlined"}
        size="small"
        style={{flexGrow: 1}}>
        {buttonValue}
    </Button>
}

export function SelectBoolInapplicable({
    value,
    onChange,
    options
}) {
    const [isOpen, setIsOpen] = useState(false)

    const inapplicable = "Неприложимо"
    const yes = "Да"
    const no = "Не"

    function getButton(buttonValue) {
        return <GroupButton
            value={value}
            buttonValue={buttonValue}
            setIsMenuOpen={setIsOpen}
            onChange={onChange}
        />
    }

    const boolSelect = <ButtonGroup variant="contained"
        style={{ flexGrow: 1 }}>
        {getButton(yes)}
        {getButton(no)}
    </ButtonGroup>

    const inapplicableButton = getButton(inapplicable)

    return <div className="select-bool-inapplicable">
        <ButtonGroup orientation="vertical">
            <ButtonGroup>
                {value === inapplicable ? inapplicableButton : boolSelect}

                <Button onClick={() => setIsOpen(o => !o)} size="small">
                    <ChevronRight
                        style={{
                            transform: isOpen ? "rotate(90deg)" : "none",
                            transition: "transform 0.3s"
                        }} />
                </Button>
            </ButtonGroup>

            {isOpen ?
                value === inapplicable ?
                    boolSelect
                    :
                    inapplicableButton
                :
                null}
        </ButtonGroup>
    </div>
}