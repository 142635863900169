import { createContext, useEffect, useMemo, useRef, useState } from "react";
import {
    Backdrop,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    LinearProgress,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { CommonStageInfo } from "./stageInfoSection/Common";
import { CompanyDetails, CompanyDetailsWrapper } from "./stageInfoSection/CompanyDetails";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import GeneralStep from "./GeneralStep";
import { useAuditContext } from "../auditContext";
import Popup from "reactjs-popup";
import ContentHeader from "./ContentHeader";
import ReactDOM from 'react-dom/client';
import { IdentificationContextProvider, useIdentificationContext, identificationDetailsContext } from "./identificationContext";
import { TableContextProvider, useTableContext } from "./tableContext";
import { exportTableToExcel } from "../../../apiUtils/requests";
import { backendUrl } from "../../../apiUtils/urls";
import { SaveButton } from "./SaveButton";
import axios from "axios";

function useSaveAsPDF() {
    const identification = useIdentificationContext()
    const [waitingForData, setWaitingForData] = useState(false)
    const printFrameClone = useRef()
    const tableContextValue = useTableContext()

    const { awaitedStepRequestsCount, setAwaitedStepRequestsCount } = useAuditContext()

    async function renderTopSection() {
        const topSection = printFrameClone.current.querySelector("#company-details")
        if (!topSection) {
            return
        }

        console.log("Start rendering top section")

        return new Promise((resolve) => ReactDOM
            .createRoot(topSection.parentNode)
            .render(<identificationDetailsContext.Provider value={identification}>
                <CompanyDetails
                    open
                    onMount={resolve}
                    isLoaded={identification.isLoaded}
                    companyDetails={identification.data} />
            </identificationDetailsContext.Provider>));
    }

    async function saveAsPDF() {

        if (printFrameClone.current) {
            console.log("The previous request for save still isn't completed")
            return
        }

        setWaitingForData(true)

        setAwaitedStepRequestsCount(c => c + 1)
        await tableContextValue.fetchNextRows()
        setAwaitedStepRequestsCount(c => c - 1)

        console.log("prepared all rows for export")
    };

    async function onEverythingLoaded() {
        setWaitingForData(false)

        const printFrame = document.getElementById("print-frame")

        const clone = printFrame.cloneNode(true)
        printFrameClone.current = clone

        const root = document.getElementById("root")
        root.appendChild(clone)

        await renderTopSection()


        window.print()

        root.removeChild(printFrameClone.current)

        printFrameClone.current = null
    }

    useEffect(() => {
        if (!waitingForData ||
            !identification.isLoaded ||
            tableContextValue.totalRowsCount !== tableContextValue?.rows?.length ||
            awaitedStepRequestsCount) {
            return
        }

        // Wait for the loading indication to hide
        setTimeout(() => {
            onEverythingLoaded()
        }, 500)
    }, [identification, waitingForData, tableContextValue, awaitedStepRequestsCount])

    return saveAsPDF
}

function parseHeader(header) {
    if (!header) {
        return
    }
    const matches = [...header.matchAll(/[^;]*=[^;]*/g)]
    const result = {}

    for (const group of matches) {
        const [key, value] = group[0].split("=")
        if (key) {
            result[key] = value
            continue
        }

        result[group[0]] = undefined
    }

    return result
}

function ExportButtons({ }) {
    const saveAsPDF = useSaveAsPDF()
    const { auditId, setAwaitedResponsesCount } = useAuditContext()
    const { selectedTable } = useTableContext()

    async function exportToExcel() {
        //window.location.href = `${backendUrl}/table/excel?auditId=${auditId}&tableId=${selectedTable.id}`
        setAwaitedResponsesCount(c => c + 1)
        const resp = await fetch(`${backendUrl}/table/excel?auditId=${auditId}&tableId=${selectedTable.id}`)
        const blob = await resp.blob()
        setAwaitedResponsesCount(c => c - 1)
        const a = document.createElement("a")
        a.style.display = 'none';
        a.href = URL.createObjectURL(blob)
        document.body.append(a)
        a.click()
        document.body.removeChild(a)
    }

    return <Box display="inline-flex" alignItems="center" className="stage-top-actions">
        <Typography fontSize="0.75em" alignSelf="center">Запиши като</Typography>
        <IconButton onClick={exportToExcel} className="export-button">
            <img src="/images/xls.svg" alt="excel export" />
        </IconButton>

        <IconButton onClick={saveAsPDF} className="export-button">
            <img src="/images/pdf.svg" alt="pdf export" />
        </IconButton>
    </Box>
}

function TopSection({ currentMenuOptionName }) {
    return <Grid
        container
        width="100%"
        marginBottom="10px"
        alignItems="flex-end">
        <ContentHeader style={{ marginRight: "auto" }}>
            {currentMenuOptionName}
        </ContentHeader>

        <ExportButtons />

        <SaveButton />
    </Grid>
}

function SaveWarningPopup({
    isOpen,
    setIsOpen,
    onSave
}) {
    function saveAndClose() {
        setIsOpen(false);
        onSave()
    }

    return <Popup
        sx={{ width: "200px" }}
        open={isOpen}
        closeOnDocumentClick
        onClose={() => setIsOpen(false)}
    >
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
            }}
        >
            <Typography>Въведените данни не са запазени!</Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "20px",
                    gap: "10px",
                }}
            >
                <Button variant="contained" onClick={saveAndClose}>
                    Запази
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => setIsOpen(false)}
                >
                    Отказ
                </Button>
            </Box>
        </Box>
    </Popup>
}

export function ContentFullScreenWrapper() {
    const fullScreenHandle = useFullScreenHandle();

    return <FullScreen className="full-screen" handle={fullScreenHandle}>
        <GeneralStep fullScreenHandle={fullScreenHandle} />
    </FullScreen>
}

export default function MainStageContent({ currentMenuOptionName }) {
    return <IdentificationContextProvider>
        <TableContextProvider>
            <MainStageContentWithoutContext currentMenuOptionName={currentMenuOptionName} />
        </TableContextProvider>
    </IdentificationContextProvider>
}

export function MainStageContentWithoutContext({ currentMenuOptionName, className, noCompanyDetails }) {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    return <Grid
        container
        flexDirection="column"
        flexBasis={0}
        width={0}
        flexGrow={1}
        wrap="nowrap"
        position="relative"
        className={"main-stage-content" + (className ? " " + className : "")}
    >
        <TopSection currentMenuOptionName={currentMenuOptionName} />

        <div id="print-frame">
            <Grid
                flexWrap="nowrap"
                container
                flexDirection="column"
                height="100%"
            >
                {noCompanyDetails ? null : <div>
                    {/* Temporary hard-coded */}
                    {currentMenuOptionName === "Идентификация" ? <CommonStageInfo /> : <CompanyDetailsWrapper />}
                </div>}

                <ContentFullScreenWrapper />

            </Grid>
        </div>

        <SaveWarningPopup isOpen={isPopupOpen} setIsOpen={setIsPopupOpen} />
    </Grid>
}