import { Typography } from "@mui/material";

export default function ContentHeader({ children, style }) {
    return <Typography
        sx={{
            color: "#02a1d5",
            fontWeight: "600",
            fontSize: "1.125em",
            ...style
        }}
    >
        {children}
    </Typography>
}