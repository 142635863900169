import { Button, Paper, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Navigate } from 'react-router-dom';
// import { useKeycloak } from '@react-keycloak/web';
import ArrowRightIcon from "@mui/icons-material/ArrowForward"
import {useAuth as useKeycloak} from '../apiUtils/keycloak';

export const LandingPage = () => {
  const authHandler = useKeycloak()

  return (
    <Paper
      elevation={0}
      variant="outlined"
      square={false}
      className="paper-container"
      sx={{ 
        width: "100%", 
        height: "100%", 
        display: "flex", 
        flexDirection: "column", 
        alignItems: "center", 
        justifyContent: "center",
         gap: "30px", 
        padding: "5em",
        boxSizing: "border-box" }}>

      <Box sx={{ backgroundColor: "#212B34", padding: "15px", borderRadius: "10px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}>
        <img src="images/logo.png" alt="logo" sx={{ height: "30px" }} />
      </Box>

      <Typography variant="h5" textAlign="center" fontWeight="bold">Добре дошли в уеб приложението за финансов одит</Typography>

      <Typography variant='h6' textAlign="center">
        За да видите съдържанието е необходимо да влезете в профила си
        <ArrowRightIcon style={{ verticalAlign: "middle", margin: "0 0.25em" }} />
        <Button size="medium" variant="contained" color="primary" sx={{ width: "200px" }} onClick={() => authHandler.login()}>Вход</Button>
      </Typography>

    </Paper>
  )
}
