export const backendUrl = "https://audit.infn.dev/backend/api"
export const formbuilderApiUrl = "https://audit-formbuilder.infn.dev/ss"

export const urls = {
    POST_AUDIT: "audit/create",
    GET_COMPANY: "company/eik",
    GET_DOCUMENTS: "document/all",
    ADD_FAVOURITE: "document/favourites",
    GET_ALLYEARS: "year/all",
    GET_POSTYEAR: "year/create"
}

export const CreateFieldUrl = {
    remote : 'https://audit-formbuilder.infn.dev/ss',
    local : 'http://localhost:3000'
};
