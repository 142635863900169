import { Dialog, DialogContent, IconButton } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import OpenInFull from "@mui/icons-material/OpenInFull"
import CloseIcon from "@mui/icons-material/Close"

function FullContentPopup({ text }) {
    const [isOpen, setIsOpen] = useState(false)

    return <>
        <IconButton onClick={() => setIsOpen(true)}>
            <OpenInFull />
        </IconButton>

        <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}>
            <IconButton
                style={{
                    marginRight: "10px",
                    marginTop: "10px",
                    marginLeft: "auto"
                }}
                onClick={() => setIsOpen(false)}>
                <CloseIcon />
            </IconButton>
            <DialogContent
            style={{paddingTop: 0}}>
                {text}
            </DialogContent>
        </Dialog>
    </>
}

export function TextField({ elementData }) {
    const [hasOverflow, setHasOverflow] = useState(false)
    const ref = useRef()

    useEffect(() => {
        function onResize() {
            setHasOverflow(ref.current.clientHeight + 30 < ref.current.scrollHeight)
        }

        const resizeObserver = new ResizeObserver(onResize)
        resizeObserver.observe(ref.current)

        return () => resizeObserver.disconnect()
    }, [])

    return <p className="type-text"
        ref={ref}>
        {hasOverflow ? <FullContentPopup text={elementData?.label} /> : null}
        {elementData?.label}
    </p>
}