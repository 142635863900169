import { stringify } from "query-string";
import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  DELETE,
  GET_MANY,
  GET_MANY_REFERENCE,
  DELETE_MANY,
  UPDATE_MANY,
} from "react-admin";
import { Navigate } from "react-router-dom";
import { SessionExpired } from "../Pages/SessionExpired";
import {useAuth as useKeycloak} from "./keycloak";
import { backendUrl } from "./urls";
// import { useKeycloak } from "@react-keycloak/web";

const apiUrl = `${backendUrl}`;

// Wraps the data provider with keycloak from context
export function useAppDataProvider()
{
  const keycloak = useKeycloak()
  return (...params) => appDataProvider(keycloak, ...params)
}

export async function appDataProvider(keycloak, type, resource, params) {
  let url = `${apiUrl}`;
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${keycloak.token}`,
    },
  };
  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination || {};
      const search = params.filter?.search;
      const auditYear = params.filter?.auditYear;
      const query = {
        page: page,
        count: perPage,
        search,
        auditYear,
      };
      url = `${apiUrl}/${resource}?${stringify(query)}`;
      break;
    }
    case GET_ONE:
      url = `${apiUrl}/${resource}/${params.id}`;
      break;
    case CREATE:
      url = `${apiUrl}/${resource}`;
      options.method = "POST";
      options.body = JSON.stringify(params.data);
      break;
    case UPDATE:
      url = `${apiUrl}/${resource}/${params.id}`;
      options.method = "PUT";
      options.body = JSON.stringify(params.data);
      break;
    case UPDATE_MANY:
      const queryUpdate = {
        filter: JSON.stringify({ id: params.ids }),
      };
      url = `${apiUrl}/${resource}?${stringify(queryUpdate)}`;
      options.method = "PATCH";
      options.body = JSON.stringify(params.data);
      break;
    case DELETE:
      url = `${apiUrl}/${resource}/${params.id}`;
      options.method = "DELETE";
      break;
    case DELETE_MANY:
      const query = {
        filter: JSON.stringify({ id: params.ids }),
      };
      url = `${apiUrl}/${resource}?${stringify(query)}`;
      options.method = "DELETE";
      break;
    case GET_MANY: {
      const { page, perPage } = params.pagination || {};
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
      };
      url = `${apiUrl}/${resource}?${stringify(query)}`;
      break;
    }
    case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify({
          ...params.filter,
          [params.target]: params.id,
        }),
      };
      url = `${apiUrl}/${resource}?${stringify(query)}`;
      break;
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }

  const res = await fetch(url, options);

  if (res.status === 401){
    return <SessionExpired/>;
  }


  const json = await res.json()

  switch (type) {
    case GET_LIST:
      return {
        data: json.data,
        total: json.elements,
      };
    case GET_MANY_REFERENCE:
      return {
        data: json,
      };
    case CREATE:
      return { data: { ...params.data, id: json.id } };
    case DELETE_MANY:
      return { data: json || [] };
    default:
      return { data: json };
  }
}
