import "./UploadFile.scss";

import { Button, Grid, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete"
import AttachFileIcon from "@mui/icons-material/AttachFile"
import { useTableContext } from "../../ViewAudit/tableContext";
import { getFileUrl } from "../../../../apiUtils/requests";

function UploadedFile({ name, onDelete, onDownload }) {
  return <Grid container alignItems="center" wrap="nowrap" width="auto">
    <Button
      variant="text"
      onClick={() => onDownload(name)}
      className="file-button">
      <div className="ellipsis-wrapper" title={name}>
        {name}
      </div>
    </Button>
    <IconButton
      onClick={() => onDelete(name)}>
      <DeleteIcon />
    </IconButton>
  </Grid>
}

async function fileToString(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()

    fileReader.onload = (e) => {
      if (e.target.readyState != 2)
        return;

      if (e.target.error) {
        console.error('Error while reading file');
        reject(e.target.error)
      }

      resolve(e.target.result)
    }

    fileReader.readAsText(file)
  })
}

const UploadFile = ({ elementData }) => {
  // const { stepData, auditId } = useAuditContext();
  const { updateCell } = useTableContext()
  // const [hasErrors, setHasErrors] = useState(false);
  const id = elementData.id;
  // const [files, setFiles] = useState(elementData.value ? [elementData.value] : [])



  // function inputHandler(ev) {
  //   const newValue = ev.target.value;
  //   setFieldsSubmitData((oldState) => {
  //     const arrCopy = [...oldState];
  //     const i = findIndexOfField(elementData.key, oldState);
  //     arrCopy[i] = { key: elementData.key, value: newValue };
  //     return arrCopy;
  //   });
  //   setInputValue(newValue);
  //   // validateData(newValue);
  // }

  function validateData(value) {
    let valid = false;
    value = value.trim();

    if (!elementData.required) {
      valid = true;
    } else {
      if (value === "") {
        valid = false;
      } else {
        valid = true;
      }
    }

    // if (valid) {
    //     setHasErrors(false);
    // } else {
    //     setHasErrors(true);
    // }
    return valid;
    // const regExp = new RegExp(elementData.validation);

    // if(!regExp.test(newValue)) {
    //     return false
    // } else {
    //     return true;
    // }
  }

  async function onUpload(event) {
    const files = [...event.target.files];
    console.log(files)
    // let names = []

    updateCell({
      id: elementData.id,
      files: [
        ...files,
        ...(elementData.files || [])
      ],
      value: [
        ...(Array.isArray(elementData.value) ? elementData.value : []),
        ...files.map(f => f.name)
      ]
    })

    // setFiles(files => [...files, ...names])
  };

  function deleteFile(name) {
    updateCell({
      id: elementData.id,
      files: (elementData.files || []).filter(file => file.name !== name),
      value: (elementData.value || []).filter(file => file.name !== name),
    })
  }

  async function downloadFile(name) {
    const file = elementData.files?.find(file => file.name === name)

    const url = file ?
      URL.createObjectURL(file)
      :
      (await getFileUrl(name))?.data?.url

    window.open(url, "_blank")
    // if (file) {
    //   const url =
    //   //URL.revokeObjectURL(url)
    //   return
    // }
  }


  return (
    <div key={id} className="upload-file-wrapper">

      <Grid
        className="upload-file"
        container
        alignItems="center"
        wrap="nowrap"
        gap={1}
      >
        {elementData.label}
        <Button
          className="btn-file"
          variant="text"
          component="label"
          startIcon={<AttachFileIcon sx={{ color: "black" }} />}>
          Прикачи документ
          <input type="file" onChange={onUpload} hidden multiple />
        </Button>

      </Grid>
      <Grid container gap={1} width="auto" className="files-list" >
        {!Array.isArray(elementData.value) ?
          null :
          elementData.value.map((file, index) => <UploadedFile
            key={index}
            name={file}
            onDelete={deleteFile}
            onDownload={downloadFile}
          />)
        }
      </Grid>
      {/* {hasErrors ? (
        <p className="error-msg">{elementData.validationError}</p>
      ) : null} */}
    </div>
  );
};

export default UploadFile;
