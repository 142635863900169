import {
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { backendUrl } from "../../apiUtils/urls";
import {useAuth as useKeycloak} from "../../apiUtils/keycloak";
// import { useKeycloak } from "@react-keycloak/web";

export const ProfileDetails = () => {
  const authHandler = useKeycloak()
  const [checked, setChecked] = React.useState([0]);
  const tokenAuth = authHandler.token;
  const [allAudits, setAllAudits] = useState();
  let data = useLocation();

  useEffect(() => {
    const config = {
      headers: {
        authorization: `Bearer ${tokenAuth}`,
      },
    };
      axios
      .get(`${backendUrl}/audit/all`, config)
      .then((response) => setAllAudits(response.data));
  }, []);

  console.log(allAudits)

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <Paper
      elevation={0}
      variant="outlined"
      square={false}
      className="paper-container"
      sx={{
        width: "90%",
        height: "90vh",
        alignSelf: "center",
        margin: "10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: "90%", alignSelf: "center", margin: "10px" }}>
        <Typography sx={{ fontSize: "18px", margin: "20px 0px 10px 20px" }}>
          Начало / Настройки / Потребител / {data.state.name}{" "}
          {data.state.lastName}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: "10%",
          marginTop: "2%",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <Typography
            sx={{ color: "#00a9e0", fontWeight: "600", marginBottom: "20px" }}
          >
            Лични данни
          </Typography>
          <Typography>
            <b>Име: </b>
            {data.state.name} {data.state.lastName}
          </Typography>
          <Typography>
            <b>Имейл: </b>
            {data.state.email}
          </Typography>
          {data.state.profiles?.map((profile) => {
            if (profile?.role === "AUDITOR")
              return (
                <Typography>
                  <b>Тип профил:</b> Главен Одитор
                </Typography>
              );
            else
              return (
                <Typography>
                  <b>Тип профил:</b> Асистент Одитор
                </Typography>
              );
          })}
          <Typography>
            <b>Дата на регистрация: </b>
          </Typography>
          <Button sx={{ marginTop: "20px" }} variant="contained">
            Запази
          </Button>
        </Box>
        <Box>
          <Typography
            sx={{ color: "#00a9e0", fontWeight: "600", marginBottom: "20px" }}
          >
            Достъп до одити
          </Typography>
          <List
            sx={{
              width: "100%",
              maxHeight: "50vh",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
              gap: "5%",
              alignItems: "left",
              marginTop: "10px",
            }}
          >
            {allAudits?.data.map((audit) => {
              const labelId = `checkbox-list-label-${audit.name}`;

              return (
                <ListItem key={audit.id} disablePadding>
                  <ListItemButton
                    role={undefined}
                    onClick={handleToggle(audit)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked.indexOf(audit) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={`Име на одит: ${audit.name}, Отговорен одитор: ${audit.auditor}`}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Box>
    </Paper>
  );
};
