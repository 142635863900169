import './Radio.scss';
import { useState } from 'react';
import { useTableContext } from '../../ViewAudit/tableContext';

const Radio = ({ elementData }) => {
    const id = elementData.id;
    const [radioValue, setRadioValue] = useState('');
    const [hasErrors, setHasErrors] = useState(false);
    const elementClasses = 'radio-wrapper ' + elementData.styleClass;

    const {updateCell} = useTableContext()

    function radioHandler(ev) {
        const newValue = ev.target.value;
        updateCell({
            id: elementData.id,
            value: newValue
        })
        setRadioValue(newValue);
        // validateData(newValue);
    }

    function validateData(value) {
        let valid = false;

        if (!elementData.required) {
            valid = true;
        } else {
            if (value === '') {
                valid = false;
            } else {
                valid = true;
            }
        }

        // if (valid) {
        //     setHasErrors(false);
        // } else {
        //     setHasErrors(true);
        // }

        return valid;
    }

    const options = elementData.options.map((option, i) => <div key={i}>
        <input
            name={elementData.label}
            type="radio"
            id={option}
            value={option}
            onChange={radioHandler}
        />{' '}
        <label htmlFor={option}>{option}</label>
    </div>);

    const radioEl = (
        <div className={elementClasses} key={id}>
            <label htmlFor="">{elementData.label}</label>
            {options}
            {hasErrors ? (
                <p className="error-msg">{elementData.validationError}</p>
            ) : null}
        </div>
    );

    return radioEl;
};

export default Radio;
