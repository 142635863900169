import axios from "axios";
import { backendUrl } from "./urls";

export const getStages = () => axios(`${backendUrl}/stage`)

export const getStageTest = (stageId) => axios(`${backendUrl}/stage/test?stageId=${stageId}`)

export const getTestTables = (testId, auditId) => axios(`${backendUrl}/stage/table?testId=${testId}&auditId=${auditId}`)

export const getTableRows = (tableId, auditId, signal) => axios(`${backendUrl}/table?auditId=${auditId}&tableId=${tableId}`, {signal})

export const exportTableToExcel = (auditId, tableId) => axios(`${backendUrl}/table/excel?auditId=${auditId}&tableId=${tableId}`)

export const getTableRow = (tableId, auditId, rowId, signal) => axios(`${backendUrl}/table/row?auditId=${auditId}&tableId=${tableId}&row=${rowId}`, {signal})

export const updateTableCell = (auditId, cellId, value) => axios.put(`${backendUrl}/table/cell`, {
    auditId,
    cellId,
    value
})

export const saveTable = (auditId, tableId, cells) => axios.post(`${backendUrl}/table/save`, {
    auditId,
    tableId,
    cells
})

export const createTableRow = (auditId, tableId) => axios.post(`${backendUrl}/table/row`, {
    auditId,
    tableId
})


export const getAuditById = (auditId) => axios(`${backendUrl}/audit?id=${auditId}`)

export const getFascalYears = () => axios(`${backendUrl}/year/all`)
export const createFascalYear = (year) => axios.post(`${backendUrl}/year/create`, {
    year
})

export const getCompanyEGO = () => axios(`${backendUrl}/company/ego`)

export const getFileUrl = (fileName) => axios(`${backendUrl}/table/file/download?fileName=${fileName}`)