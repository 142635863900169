import { Paper, Typography, Grid, Box } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { HYPERLINKS_INFO } from "../hyperLinksArray";
import { useState } from "react";


function Arrow({ direction, onClick }) {

  const icon =
    direction === "left" ?
      <ArrowBackIosNewIcon sx={{ cursor: "pointer" }} />
      :
      <ArrowForwardIosIcon sx={{ cursor: "pointer" }} />

  return (
    <div className="arrow" onClick={onClick}>
      {icon}
    </div>
  );
}

export default function HelpfulLinks() {
  const [index, setIndex] = useState(0);
  const visibleLinks = HYPERLINKS_INFO[index];
  const numSlides = HYPERLINKS_INFO.length;

  const onArrowClick = (direction) => {
    const increment = direction === "left" ? -1 : 1;
    const newIndex = (index + increment + numSlides) % numSlides;
    setIndex(newIndex);
  };

  return <Grid container
    flexDirection="column"
    className="useful-links"
  >
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <LinkIcon sx={{ alignSelf: "center", color: "#00a9e0" }} />
      <Typography sx={{ fontSize: "18px", margin: "10px 0px 10px 10px" }}>
        Полезни връзки
      </Typography>
    </Box>
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Arrow
        direction="left"
        onClick={() => onArrowClick("left")}
      />
      <Paper
        elevation={0}
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          height: "auto",
        }}
      >
        {visibleLinks.map((element, index) => (
          <Box key={index} sx={{ border: "1px solid #D8D8D8", padding: "10px" }}>
            <a
              className="exLink"
              target="_blank"
              rel="noreferrer"
              href={element.href}
            >
              {element.title}
            </a>
          </Box>
        ))}
      </Paper>
      <Arrow
        direction="right"
        onClick={() => onArrowClick("right")}
      />
    </Box>
  </Grid>
}
