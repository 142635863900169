import {
  Button,
  Paper,
  Typography,
  TextField,
  InputLabel,
  NativeSelect,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import {
  Datagrid,
  List,
  ResourceContextProvider,
  TextField as BaseTextField,
} from "react-admin";
import Popup from "reactjs-popup";
import {useAuth as useKeycloak} from "../../apiUtils/keycloak";
import { backendUrl } from "../../apiUtils/urls";
import ProfileField from "./ProfileCustomField";
// import { useKeycloak } from "@react-keycloak/web";

export const Profiles = (props) => {
  const authHandler = useKeycloak()
  
  const [body, setBody] = useState({
    name: "",
    family: "",
    email: "",
  });

  console.log(body);

  const handleChange = (e) => {
    const value = e.target.value;
    setBody({
      ...body,

      [e.target.name]: value,
    });
  };

  const tokenAuth = authHandler.token;

  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const openModal = () => {
    setOpen((o) => !o);
  };

  function addUser() {
    const data = {
      name: body.name,
      family: body.family,
      email: body.email,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${tokenAuth}`,
        "Content-Type": "application/json",
      },
    };
    axios
      .post(`${backendUrl}/account/user/add`, data, config)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <Paper
      sx={{
        width: "90%",
        display: "flex",
        flexDirection: "column",
        alignSelf: "center",
        margin: "10px",
        gap: "10px",
      }}
    >
      <Typography sx={{ fontSize: "18px", margin: "10px 0px 10px 20px" }}>
        Начало / Потребители{" "}
      </Typography>
      <Box
        sx={{
          margin: "10px 0px 10px 20px",
          display: "flex",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <Button variant="contained" onClick={openModal}>
          Добави нов
        </Button>
      </Box>
      <ResourceContextProvider value="account/users">
        <List
          sx={{
            width: "90%",
            alignSelf: "center",
            boxShadow: "none",
            "& .css-bhp9pd-MuiPaper-root-MuiCard-root": {
              borderRadius: "none",
              boxShadow: "none"
            },
          }}
          exporter={false}  
          actions={null}
        >
          <Datagrid className="classes" bulkActionButtons={false}>
            <ProfileField
              label="Име"
              color="#00A9ED"
              render={(record) => `${record.name} ${record.lastName}`}
            />
            <BaseTextField source="role" label="Позиция" />
            <BaseTextField source="email" label="Имейл" />
          </Datagrid>
        </List>
      </ResourceContextProvider>
      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        <div className="popUp-content">
          <Box
            sx={{
              gap: "10%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "10px",
              marginTop: "10px",
            }}
          >
            <Typography variant="h6">Данни за потребителя</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <TextField
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label="Име"
                name="name"
                value={body.name}
                onChange={(e) => handleChange(e)}
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label="E-mail"
                name="email"
                value={body.email}
                onChange={(e) => handleChange(e)}
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label="Фамилия"
                name="family"
                value={body.family}
                onChange={(e) => handleChange(e)}
              />
              <InputLabel
                variant="standard"
                htmlFor="uncontrolled-native"
                sx={{ fontSize: "12px", marginTop: "2%", marginRight: "auto" }}
              >
                Позиция
              </InputLabel>
              <NativeSelect
                inputProps={{
                  name: "auditYear",
                  id: "uncontrolled-native",
                }}
                sx={{ width: "100%", height: "29px", marginBottom: "1%" }}
                defaultValue="default"
              >
                <option value="AUDITOR">Одитор</option>
                <option value="ASSISTANT_AUDITOR">Aсистент</option>
              </NativeSelect>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "20px",
                gap: "25px",
              }}
            >
              <Button size="medium" variant="contained" onClick={addUser}>
                Добави
              </Button>
              <Button size="medium" variant="contained" onClick={closeModal}>
                Откажи
              </Button>
            </Box>
          </Box>
          {/* <Box>
                {confirmation && (
                  <Typography>
                    <p>{confirmation}</p>
                  </Typography>
                )}
              </Box> */}
        </div>
      </Popup>
    </Paper>
  );
};
