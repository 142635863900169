import { Box } from "@mui/system";
import { Button, Dialog, IconButton, Menu, MenuItem, Popover } from "@mui/material";
import { useAuth } from "../apiUtils/keycloak";
import { useRef, useState } from "react";
import { UserMenu } from "react-admin";
import UserIcon from "@mui/icons-material/Person"

function AccountPopup({ isOpen, setIsOpen, buttonRef }) {
    const authHandler = useAuth()

    return <Menu
        open={isOpen}
        onClose={() => setIsOpen(false)}
        anchorEl={buttonRef?.current}>
        <MenuItem>
            <Button
                onClick={() => authHandler?.logout()}
            >
                Изход
            </Button>
        </MenuItem>
    </Menu>
}

function AccountButton() {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const buttonRef = useRef()

    return <>
        <IconButton ref={buttonRef} onClick={() => setIsMenuOpen(true)}
            style={{
                backgroundColor: "white",
                borderRadius: "20px"
            }}>
            <UserIcon />
        </IconButton>

        <AccountPopup
            buttonRef={buttonRef}
            isOpen={isMenuOpen}
            setIsOpen={setIsMenuOpen}
        />
    </>
}

export function AccountSection() {
    const authHandler = useAuth()

    if (!authHandler.token) {
        return <Button
            sx={{ color: "#ffffff" }}
            onClick={() => authHandler?.login()}
        >
            Вход
        </Button>
    }

    return <Box sx={{ display: "flex", gap: "10px", marginRight: "10px", alignItems: "center" }}>
        {authHandler?.tokenParsed.preferred_username}
        <AccountButton />
    </Box>

}