import { useState, useEffect, useMemo } from "react";

export default function OptimizedInput({ Component, ...props }) {
    const [value, setValue] = useState(props.value)

    // useMemo(() => {
    //     if (value === props.defaultValue) {
    //         return
    //     }

    //     setValue(props.defaultValue)
    // }, [props.defaultValue])

    useMemo(() => {
        if (value === props.value) {
            return
        }

        setValue(props.value)
    }, [props.value])

    const finalValue = (value === null || value === undefined) ? undefined : value || ""

    return <Component
        {...props}
        value={finalValue}
        onChange={e => setValue(e.target.value)}
        onBlur={() => props.onChange?.(value)} />
}