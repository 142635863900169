import * as stepService from "../../../../Services/step";
import "./Flow.scss";
import { useState } from "react";
import { Box, Button } from "@mui/material";
import axios from "axios";
import { CreateFieldUrl } from "../../../../apiUtils/urls";
import { useAuditContext } from "../../auditContext";

const Flow = ({ elementData }) => {
  const elementClasses = "flow-wrapper";

  const { openSubstep } = useAuditContext();

  // const postData = {
  //   fromDirectionId: stepData.directionId,
  //   submissionId: submissionId,
  //   directionId: elementData.directionId,
  //   fields: fieldsSubmitData,
  //   fromFlowFieldId: elementData.key,
  //   activityId: 0,
  // };

  async function onClick() {
    openSubstep(elementData.directionId, elementData.key)
    // const newStepData = await stepService.post(postData);
    // updateStepData(newStepData);
    // axios.get(
    //   `${CreateFieldUrl.remote}/step/direction/${stepData.directionId}/${stepData.submissionId}/view`
    // )
  }

  return (
    <div
      className={
        elementData.label === "Напред"
          ? "flow-wrapper-backward"
          : "flow-wrapper-forward"
      }
    >
      <Button onClick={onClick} variant="contained" disabled={!elementData.directionId}>
        {elementData.label}
      </Button>
    </div>
  );
};

export default Flow;
