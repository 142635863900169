import "./Checkbox.scss";
import { Checkbox } from "@mui/material";
import { useTableContext } from "../../ViewAudit/tableContext";
import Radio from "./Radio";

const CheckBoxComponent = ({ elementData }) => {
  const elementClasses = "checkbox-wrapper " + elementData.styleClass;

  const {updateCell} = useTableContext()


  const checkHandler = (ev) => {
    const newValue = ev.target.checked;
    updateCell({
      id: elementData.id,
      value: newValue
    })
  };


  if(elementData.options){
    return <Radio elementData={elementData}/>
  }

  return (
    <div className={elementClasses}>
      <Checkbox id={elementData.key} type="checkbox" onChange={checkHandler} />
      <label htmlFor={elementData.key}>{elementData.label}</label>
    </div>
  );
};

export default CheckBoxComponent;
