// import { useKeycloak } from "@react-keycloak/web";
import { Navigate, useLocation } from "react-router-dom";
import { SessionExpired } from "./Pages/SessionExpired";
import { LandingPage } from "./Pages/LandingPage";
import {useAuth} from "./apiUtils/keycloak";

const PrivateRoute = ({ children }) => {
  const authHandler = useAuth();
  //const urlHash = useLocation().hash
  // console.log('authHandler', authHandler);

  // if(!initialized && !urlHash.includes("error=login_required") && !urlHash.includes("state"))
  // {
  //   console.log(keycloak)
  //   keycloak.init({onLoad: "check-sso"})
  //   return null
  // }

  // console.log("Render private route")

  if (!authHandler?.token) {
    return <LandingPage/>;
  } else {
    return children
  }
  
};

export default PrivateRoute;
