import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Button,
  CircularProgress,
  List,
  ListItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { useDataProvider } from "react-admin";
import { useAuth as useKeycloak } from "../apiUtils/keycloak";
import { createFascalYear, getFascalYears } from "../apiUtils/requests";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../utils/reactQuery";
import { Notification, useNotification } from "../utils/Notification";
import { LoadingPanel } from "../utils/LoadingPanel";

function AddYear({ addYear }) {
  const [newYear, setNewYear] = useState("");
  const [errorMessage, setErrorMessage] = useState("");


  const handleChangeNewYear = (e) => {
    const fiscalYear = e.target.value;
    setNewYear(fiscalYear);

    if (errorMessage) {
      validate(fiscalYear)
    }
  };

  function validate(value) {
    if (/[12][0-9]{3}/.test(value) && value.length < 5) {
      setErrorMessage("");
    } else if (value.length >= 1) {
      setErrorMessage("Неправилна финансова година");
    } else {
      setErrorMessage("");
    }
  }

  return <Box
    sx={{
      display: "flex",
      gap: "3%",
      width: "80%",
      alignItems: "center",
      alignSelf: "center",
      marginBottom: "25px",
    }}
  >
    <TextField
      label="Финансова година"
      variant="outlined"
      onChange={handleChangeNewYear}
      onBlur={() => validate(newYear)}
      helperText={errorMessage}
      error={!!errorMessage}
    />

    <Button variant="contained" onClick={() => addYear(newYear)}>
      Добави
    </Button>
  </Box>
}

function FascalYear({ data }) {
  return <Accordion
    elevation={0}
    sx={{
      width: "80%",
      display: "flex",
      flexDirection: "column",
      alignSelf: "center",
    }}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <Typography>
        Финансова година: <b>{data.year}</b> Брой одити:{" "}
        <b>{data.audits?.length || 0}</b>
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <List sx={{ padding: 0 }}>
        {data.audits?.map((audit) => <ListItem
          key={audit.id}
          sx={{ padding: 0 }}>

          <Link
            state={audit}
            to={{
              pathname: `/audits/${audit.id}/acceptance`,
            }}
            className="link"
          >
            {audit.name}
          </Link>
        </ListItem>
        )}
      </List>
    </AccordionDetails>
  </Accordion>
}

const errorMessages = {
  alreadyExists: "This year already exists"
}

export const FiscalYears = () => {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false)

  const dataProvider = useDataProvider()

  const yearsQuery = useQuery(["years"], getAuditsByYears)
  const createYearMutation = useMutation(createYear, {
    onSuccess(newFascalYear, newYear) {
      console.log("Created: ", newFascalYear, newYear)
      queryClient.setQueryData(["years"], data => {
        const updated = [...data, {
          year: newYear,
          audits: newFascalYear?.audits
        }]

        updated.sort((a, b) => Number(b.year) - Number(a.year))

        return updated
      })

      setIsNotificationOpen(true)
    },

    onError(error) {
      setIsNotificationOpen(true)
    }
  })

  async function createYear(year) {
    const resp = await createFascalYear(year)
    if (resp.status !== 200) {
      const errorCode = resp.response?.data?.code
      const msg = errorMessages[errorCode]
      throw new Error(msg)
    }

    return resp.data
  }

  async function getAuditsByYears() {
    const resp = await getFascalYears()
    const years = resp.data

    if (!years) {
      return
    }

    const uniqueYears = [...new Set(years)]
    const auditsByYears = await Promise.all(uniqueYears?.map(async year => ({
      year,
      audits: (await dataProvider.getList("audit/all", { filter: { auditYear: year } })).data
    })))

    auditsByYears?.sort((p1, p2) => p2.year - p1.year)

    return auditsByYears
  }

  async function addYear(newYear) {
    createYearMutation.mutate(newYear)
  }

  return (
    <Paper
      elevation={0}
      variant="outlined"
      square={false}
      className="paper-container"
      sx={{
        width: "80%",
        height: "90vh",
        alignSelf: "center",
        maxHeight: "90vh",
        overflow: "auto",
        margin: "10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography sx={{ fontSize: "18px", margin: "10px 0px 10px 20px" }}>
        Начало / Финансови години{" "}
      </Typography>

      <AddYear addYear={addYear} />

      {yearsQuery.data?.map(data => <FascalYear key={data.year} data={data} />)}

      <LoadingPanel open={yearsQuery.isLoading || createYearMutation.isLoading} />

      <Notification snackbarProps={{}} notification={useMemo(() => ({
        isOpen: isNotificationOpen,
        setIsOpen: setIsNotificationOpen,
        severity: createYearMutation.isError ? "error" : "success",
        msg: createYearMutation.isError ? createYearMutation.error?.message : "New fascal year is added."
      }))} />
    </Paper>
  )
}
