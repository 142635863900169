
import {
    MenuItem,
    MenuList,
} from "@mui/material";
import { Box } from "@mui/system";
import ContentHeader from "./ContentHeader";

function MenuLink({ link, openStep, isActive }) {
    return <MenuItem selected={isActive} onClick={openStep}>
        <div>
            {link.name}
        </div>
    </MenuItem>

}

export default function StageLinks({ title, links, openStep, currentMenuOptionIndex }) {
    return <Box sx={{ width: "auto", minWidth: "200px", maxWidth: "300px" }}>
        <ContentHeader style={{ marginLeft: "10px" }}>
            {title}
        </ContentHeader>

        <MenuList className="stage-menu-links">
            {links?.map((link, index) => <MenuLink
                key={link.id}
                link={link}
                openStep={() => openStep(index)}
                isActive={index === currentMenuOptionIndex} />)}
        </MenuList>
    </Box>
}