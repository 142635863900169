import { createContext, useContext, useMemo, useState } from "react"

export const identificationDetailsContext = createContext({
    data: null,
    setData: () => { },
    isLoaded: false, 
    setIsLoaded: (isLoaded) => {}
})

export function IdentificationContextProvider({ children }) {
    const [data, setData] = useState()
    const [isLoaded, setIsLoaded] = useState(false)

    const value = useMemo(() => ({
        data,
        setData,
        isLoaded, 
        setIsLoaded
    }), [data, isLoaded])

    return <identificationDetailsContext.Provider value={value}>
        {children}
    </identificationDetailsContext.Provider>
}

export const useIdentificationContext = () => useContext(identificationDetailsContext)