export const fieldsTypes = {
    TEXT: 'TEXT',
    NEWLINE: 'NEWLINE',
    HORIZONTAL_LINE: 'HORIZONTAL_LINE',
    INPUT: 'INPUT',
    RADIO: 'RADIO',
    AREA: 'AREA',
    CONSENT: 'CONSENT',
    CHECKBOX: 'CHECKBOX',
    DROPDOWN: 'DROPDOWN',
    FLOW: 'FLOW',
    BACK: 'BACK',
    DOCUMENT: 'DOCUMENT',
    HINT: 'HINT',
    SECTION: 'SECTION',
    TABLE: 'TABLE',
    SUB_TABLE: "SUB_TABLE",
    HEADER: 'HEADER',
    ROW: 'ROW'
}