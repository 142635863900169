import {
    Paper,
    TextField,
    Typography,
    useMediaQuery,
    Button,
    InputAdornment,
    Input,
    IconButton,
    CircularProgress,
    NativeSelect,
    InputLabel,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useMemo, useState } from 'react';
import Popup from 'reactjs-popup';
import './NewAudit.css';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { backendUrl, urls } from '../../apiUtils/urls';
import * as yup from 'yup';
import { useFormik } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import { getAllYears, postNewAudit } from '../../apiUtils';
import { YearsContextProvider, useYears } from '../../utils/yearsContext';
import { validationMessages } from '../../utils/vars';

const NewAudit = () => {
    const [vatNumber, setVatnumber] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [companyDetails, setCompanyDetails] = useState('');
    const [year, setYear] = useState();
    const [contract, setContract] = useState('');
    const [letter, setLetter] = useState('');
    const [date, setDate] = useState(dayjs());
    const [open, setOpen] = useState(false);
    const [confirmation, setConfirmation] = useState(null);
    const [errorMessages, setErrorMessages] = useState({});

    const closeModal = () => setOpen(false);
    const years = useYears().getYears();
    const formattedYears = useMemo(
        () =>
            years
                ? [...new Set(years)].map((year) => ({ id: year, name: year }))
                : null,
        [years]
    );

    const validationSchema = yup.object({
        name: yup
            .string()
            .min(2, validationMessages.NAME_TOO_SHORT)
            .required(validationMessages.REQUIRED_FIELD),
        address: yup
            .string()
            .min(10, validationMessages.NOT_FULL_ADDRESS)
            .required(validationMessages.REQUIRED_FIELD),
        auditor: yup.string().required(validationMessages.REQUIRED_FIELD),
    });
    const formik = useFormik({
        initialValues: {
            auditor: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log('submits');
            const body = Object.assign(data, values);
            const formData = new FormData();

            if (body) {
                formData.append(
                    'dto',
                    new Blob([JSON.stringify(body)], {
                        type: 'application/json',
                    })
                );
            }
            formData.append('letter', letter);
            formData.append('contract', contract);
            // console.log(letter);
            postNewAudit(formData);
            formik.resetForm();
        },
    });

    async function getCompanyDetails(eik) {
        fetch(`${backendUrl}/${urls.GET_COMPANY}?eik=${eik}`)
            .then((response) => response.json())
            .then((company) => {
                setIsLoading(false);
                setCompanyDetails(company);

                if (company?.name) {
                    formik.values.name = company.name;
                }

                if (company?.address) {
                    formik.values.address = company?.address;
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const openModal = () => {
        setOpen((o) => !o);
    };

    const data = {
        eik: vatNumber,
        name: companyDetails.name,
        address: companyDetails.address,
        date: date.toISOString(),
        accountAuditYear: year,
    };

    const callApi = () => {
        console.log('call Api', vatNumber);
        getCompanyDetails(vatNumber);
        setIsLoading(true);
    };
    let delay;

    useEffect(() => {
        delay = setTimeout(() => {
            if (/([0-9]{9,10})/.test(vatNumber)) {
                callApi();
                setErrorMessages((oldState) => ({ ...oldState, eik: '' }));
            } else if (vatNumber.length >= 1) {
                setErrorMessages((oldState) => ({
                    ...oldState,
                    eik: validationMessages.UNNVALID_EIK,
                }));
            } else {
                setErrorMessages((oldState) => ({ ...oldState, eik: '' }));
            }
        }, 500);
        return () => clearTimeout(delay);
    }, [vatNumber]);

    const keyDown = (e) => {
        if (e.key === 'Enter') {
            clearTimeout(delay);
            setIsLoading(true);
            callApi();
            console.log('keyDown press and ready for api call');
        }
    };

    const addFinalCheck = (e) => {
        const hasErrors = validateFields();
        if (hasErrors) {
            return;
        }
        openModal();
    };

    const isRowBased = useMediaQuery('(min-width: 500px)');

    const selectValue = (e) => {
        let value = Number(e.target.value);
        setYear(value);
        errorMsgHandler('year', '')
    };

    const handleDateChange = (newValue) => {
        setDate(newValue);
        // console.log(newValue.$d);
    };

    const changeHandlerContract = (event) => {
        let contractFile = event.target.files;
        // let fileName = fileFullPath.replace(/^.*[\\/]/, "");
        setContract(contractFile[0]);
        errorMsgHandler('contract', '');
    };
    const changeHandlerLetter = (event) => {
        let letterFile = event.target.files;
        // let fileName = file.replace(/^.*[\\/]/, "");
        setLetter(letterFile[0]);
        errorMsgHandler('letter', '')
    };

    const confirmationMessage = (
        <Typography
            variant="h7"
            sx={{ color: '#272d2f', fontFamily: 'Roboto' }}
        >
            Вие успешно добавихте {companyDetails.name} в раздел{' '}
            <Link to="/audits" className="exLInk">
                Одити
            </Link>
        </Typography>
    );

    function validateFields() {
        formik.validateForm();
        let hasErrors = !!Object.keys(formik.errors).length;
        // let hasErrors = false;
        const setRequiredError = (key) =>
            errorMsgHandler(key, validationMessages.REQUIRED_FIELD);

        if (!year) {
            setRequiredError('year');
            hasErrors = true;
        }

        if (!vatNumber) {
            setRequiredError('eik');
            hasErrors = true;
        }

        if (!letter) {
            setRequiredError('letter');
            hasErrors = true;
        }

        if (!contract) {
            setRequiredError('contract');
            hasErrors = true;
        }
        return hasErrors;
    }

    function errorMsgHandler(key, value) {
        setErrorMessages((oldState) => ({
            ...oldState,
            [key]: value,
        }));
    }

    return (
        <Paper
            elevation={0}
            variant="outlined"
            square={false}
            className="paper-container"
            sx={{
                width: '80%',
                height: '90vh',
                alignSelf: 'center',
                margin: '10px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '24px',
                        marginTop: '30px',
                        marginBottom: '5%',
                    }}
                >
                    Създаване на нов одит
                </Typography>
                {isLoading && (
                    <CircularProgress
                        sx={{ position: 'absolute', marginTop: '80px' }}
                        color="secondary"
                    />
                )}
                {!isLoading && <span></span>}
            </Box>
            <form>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: isRowBased ? 'row' : 'column',
                        justifyContent: 'center',
                        gap: '5%',
                    }}
                >
                    <Paper
                        elevation={0}
                        sx={{
                            width: isRowBased ? '45%' : '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: isRowBased ? '30px' : '5px',
                        }}
                    >
                        <Box className="textField">
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                sx={{ width: '100%' }}
                                id="eik"
                                name="eik"
                                label="ЕИК"
                                variant="standard"
                                value={vatNumber}
                                onChange={(e) => {
                                    setVatnumber(e.target.value);
                                }}
                                onKeyDown={keyDown}
                            />
                            <Typography
                                sx={{
                                    position: 'absolute',
                                    fontSize: '12px',
                                    color: '#d32f2f',
                                }}
                            >
                                {errorMessages.eik}
                            </Typography>
                        </Box>
                        <Box className="textField">
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                sx={{ width: '100%' }}
                                id="address"
                                name="address"
                                label="Седалище"
                                variant="standard"
                                value={formik.values.address}
                                onChange={formik.handleChange}
                            />
                            <Typography
                                sx={{
                                    position: 'absolute',
                                    fontSize: '12px',
                                    color: '#d32f2f',
                                }}
                            >
                                {formik.errors.address}
                            </Typography>
                        </Box>
                        <Box className="textField">
                            <InputLabel
                                variant="standard"
                                htmlFor="uncontrolled-native"
                                sx={{ fontSize: '12px', marginTop: '2%' }}
                            >
                                Година на одит
                            </InputLabel>
                            <NativeSelect
                                inputProps={{
                                    name: 'auditYear',
                                    id: 'uncontrolled-native',
                                }}
                                sx={{
                                    width: '100%',
                                    height: '29px',
                                    marginBottom: '1%',
                                }}
                                defaultValue="default"
                                onChange={(e) => selectValue(e)}
                            >
                                <option
                                    value="default"
                                    disabled
                                    className="options"
                                ></option>
                                {formattedYears?.map((year) => (
                                    <option
                                        value={year.id}
                                        className={'options'}
                                        key={year.id}
                                    >
                                        {year.name}
                                    </option>
                                ))}
                            </NativeSelect>
                            <Typography
                                sx={{
                                    position: 'absolute',
                                    fontSize: '12px',
                                    color: '#d32f2f',
                                }}
                            >
                                {errorMessages?.year}
                            </Typography>
                        </Box>
                        <Box className="textField">
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                value={contract?.name}
                                variant="standard"
                                label="Договор за одит"
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Input
                                                sx={{ display: 'none' }}
                                                id="uploadContract"
                                                type="file"
                                                accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                onChange={changeHandlerContract}
                                            />
                                            <label htmlFor="uploadContract">
                                                <IconButton component="span">
                                                    <AttachmentIcon />
                                                </IconButton>
                                            </label>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{ width: '100%' }}
                            />
                            <Typography
                                sx={{
                                    position: 'absolute',
                                    fontSize: '12px',
                                    color: '#d32f2f',
                                }}
                            >
                                {errorMessages?.contract}
                            </Typography>
                        </Box>
                    </Paper>
                    <Paper
                        elevation={0}
                        sx={{
                            width: isRowBased ? '45%' : '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: isRowBased ? '30px' : '5px',
                        }}
                    >
                        <Box className="textField">
                            <TextField
                                sx={{ width: '100%' }}
                                InputLabelProps={{ shrink: true }}
                                id="name"
                                name="name"
                                label="Име на фирма"
                                variant="standard"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                            />
                            <Typography
                                sx={{
                                    position: 'absolute',
                                    fontSize: '12px',
                                    color: '#d32f2f',
                                }}
                            >
                                {formik.errors.name}
                            </Typography>
                        </Box>
                        <Box className="textField">
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                sx={{ width: '100%' }}
                                id="auditor"
                                name="auditor"
                                label="Отговорен одитор"
                                variant="standard"
                                value={formik.values.auditor}
                                onChange={formik.handleChange}
                            />
                            <Typography
                                sx={{
                                    position: 'absolute',
                                    fontSize: '12px',
                                    color: '#d32f2f',
                                }}
                            >
                                {formik.errors.auditor}
                            </Typography>
                        </Box>
                        <Box className="textField">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    openTo="day"
                                    views={['year', 'month', 'day']}
                                    label="Дата на създаване на одита"
                                    value={date}
                                    inputFormat="DD/MM/YYYY"
                                    onChange={handleDateChange}
                                    renderInput={(params) => (
                                        <TextField
                                            variant="standard"
                                            sx={{
                                                width: '100%',
                                                alignSelf: 'center',
                                            }}
                                            {...params}
                                            helperText={null}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Box>
                        <Box className="textField">
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                value={letter?.name}
                                variant="standard"
                                label="Писмо за поемане на одиторски ангажимент"
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Input
                                                sx={{ display: 'none' }}
                                                id="uploadLetter"
                                                type="file"
                                                accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                onChange={changeHandlerLetter}
                                            />
                                            <label htmlFor="uploadLetter">
                                                <IconButton component="span">
                                                    <AttachmentIcon />
                                                </IconButton>
                                            </label>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{width: '100%'}}
                            />
                            <Typography
                                sx={{
                                    position: 'absolute',
                                    fontSize: '12px',
                                    color: '#d32f2f',
                                }}
                            >
                                {errorMessages?.letter}
                            </Typography>
                        </Box>
                    </Paper>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '30px',
                        gap: '2%',
                    }}
                >
                    <Button
                        size="medium"
                        variant="contained"
                        color="secondary"
                        onClick={addFinalCheck}
                        startIcon={<AddIcon />}
                    >
                        ДОБАВИ
                    </Button>
                    <Popup
                        open={open}
                        closeOnDocumentClick
                        onClose={closeModal}
                    >
                        <div className="popUp-content">
                            <Typography>
                                Сигурни ли сте, че искате да добавите фирма{' '}
                                <p style={{ color: '#1976d2' }}>
                                    {companyDetails.name || formik.values.name}{' '}
                                    с ЕИК: {vatNumber}
                                </p>{' '}
                                в списъка с одити?
                            </Typography>
                            <Box
                                sx={{
                                    gap: '10%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: '10px',
                                    marginTop: '10px',
                                }}
                            >
                                <Button
                                    size="medium"
                                    variant="contained"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        formik.handleSubmit(e);
                                        setConfirmation(confirmationMessage);
                                    }}
                                >
                                    Потвърди
                                </Button>
                                <Button
                                    size="medium"
                                    variant="contained"
                                    onClick={closeModal}
                                >
                                    Откажи
                                </Button>
                            </Box>
                            <Box>
                                {confirmation && (
                                    <Typography>
                                        <p>{confirmation}</p>
                                    </Typography>
                                )}
                            </Box>
                        </div>
                    </Popup>
                </Box>
            </form>
        </Paper>
    );
};

export default function NewAuditPage() {
    return (
        <YearsContextProvider>
            <NewAudit />
        </YearsContextProvider>
    );
}
