import { Button, Card, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import "./Dashboard.scss";
import StarIcon from "@mui/icons-material/Star"
import ArticleIcon from "@mui/icons-material/Article"
import SecurityIcon from "@mui/icons-material/Security"
import CommunicationIcon from "@mui/icons-material/Forum"
import TableChartIcon from "@mui/icons-material/TableChart"
import GroupsIcon from "@mui/icons-material/Groups"
import RocketIcon from "@mui/icons-material/Rocket"
import ArrowRightIcon from "@mui/icons-material/ArrowForward"
import { Link } from "react-router-dom";

function Section1() {
  return <Box className="section1">
    <Box>
      <div className="bright-rect" />
      <svg className="line" stroke="black">
        <line x1="0" y1="0" x2="100%" y2="100%" style={{ strokeWidth: 2 }} />      </svg>
    </Box>

    <Grid
      container
      alignItems="flex-end"
      flexDirection="column"
      flexWrap="nowrap"
      backgroundColor="#383838"
      className="rect">

      <Grid
        container
        alignItems="flex-end"
        textAlign="end"
        flexDirection="column"
        flexWrap="nowrap"
        marginRight="0vw">

        <Typography className="content-header large" variant="h2" width="12ch">Всичко за вашият независим </Typography>
        <Typography className="content-header large" variant="h2" width="100%">финансов одит на едно място</Typography>
        <hr className="thick-hr" style={{ marginLeft: "auto", backgroundColor: "white" }} />
      </Grid>

      <Link to="audits" style={{ textDecoration: "none" }}>
        <Grid container alignItems="center" style={{ backgroundColor: "#3C78D8", padding: "2vw", marginBottom: "1em" }}>
          <Typography variant="h4" color="white" className="content-text">Платформа за независим финансов одит</Typography>
          <ArrowRightIcon style={{ verticalAlign: "middle", marginLeft: "0.25em", color: "white" }} />
        </Grid>
      </Link>
    </Grid>
  </Box>
}

function OurGoals() {
  const goals = [
    {
      text: "Да предоставим всички необходими стандарти и изисквания за одит в готови шаблони",
      icon: ArticleIcon
    },
    {
      text: "Достъп и сигурност до одитните файлове по всяко едно време",
      icon: SecurityIcon
    },
    {
      text: "Да бъдем близо до своите клиенти, да познаваме техните проблеми и нужди и да им предоставяме уникални решения",
      icon: CommunicationIcon
    },
    {
      text: "Да предложим нещо повече от инструмент за одитори чрез вградени в системата таблици, проследяващи съответните одитни изисквания",
      icon: TableChartIcon
    },
    {
      text: "Да осигурим възможност на членове на екипа Ви да редактират на или да преглеждат работните документи едновременно в Proinspect",
      icon: GroupsIcon
    },
    {
      text: "Да предложим най-иновативните, модерни и гъвкави решения на пазара",
      icon: RocketIcon
    },
  ]

  return <Grid
    container
    flexDirection="column"
    backgroundColor="white"
    alignItems="center"
    gap={3}
    className="our-goals"
  >
    <hr className="thick-hr" style={{ backgroundColor: "black", margin: "0 auto" }} />

    <Typography variant="h3" fontWeight="bold">
      Към какво се стремим
    </Typography>

    <hr className="thick-hr" style={{ backgroundColor: "black", margin: "0 auto" }} />

    <Box padding={2}>
      <Typography color="white">
        <div className="cards-container">
          {goals.map((item, index) => {
            const IconComponent = item.icon ? item.icon : StarIcon
            return <Card key={index}>
              {<IconComponent className="card-icon" />}
              {item.text}
            </Card>
          }
          )}
        </div>
      </Typography>
    </Box>
  </Grid>
}

function Advantages() {
  const items = [
    {
      text: "Автоматизиран процес",
      icon: null
    },
    {
      text: "Лесна за ползване платформа за планиране и създаване на одит",
      icon: null
    },
    {
      text: "Бърз и лесен достъп до всички ангажименти с един клик",
      icon: null
    },
    {
      text: "Богато съдържание на всички тестове, необходими за поемане на одиторски ангажимент",
      icon: null
    },
    {
      text: "Автоматична справка от търговски регистър",
      icon: null
    },
    {
      text: "Добавяне на асистент одитори с възможност за ограничен или пълен достъп до досиетата",
      icon: null
    },
    {
      text: "Експорт на документите във формат съгласно изискванията на потребителите",
      icon: null
    },
    {
      text: "Отделна секция за генериране на необходимите документи за ЕГО към ИДЕС",
      icon: null
    },
    {
      text: "Формиране на извадка",
      icon: null
    },
    {
      text: "Всички етапи за одитния процес в едно приложение - Проинспект",
      icon: null
    },
    {
      text: "Навигация по всички тестове с цел улеснение на потребителите",
      icon: null
    },
    {
      text: "Опция за добавяне на документи в “любими” за бърза връзка",
      icon: null
    },
    {
      text: "Бърза връзка с всички полезни линкове за един одитор",
      icon: null
    }
  ]

  return <Grid
    container
    flexDirection="column"
    backgroundColor="white"
    alignItems="center"
    gap={3}
  >
    <hr className="thick-hr" style={{ backgroundColor: "black", margin: "0 auto" }} />

    <Typography variant="h3" fontWeight="bold">
      Предимства
    </Typography>

    <hr className="thick-hr" style={{ backgroundColor: "black", margin: "0 auto" }} />

    <Box backgroundColor="#383838" padding={2}>
      <Typography color="white">
        <List>
          {items.map((item, index) => <ListItem key={index}>
            <ListItemIcon>{item.icon ? <item.icon style={{ color: "white" }}></item.icon> : <StarIcon style={{ color: "white" }} />}</ListItemIcon>
            <ListItemText>{item.text}</ListItemText>
          </ListItem>)}
        </List>
      </Typography>
    </Box>
  </Grid>
}

export const Dashboard = () => {
  return <Grid
    className="page-content dashboard"
    style={{
      flexDirection: "column"
    }}
    gap="2vw"
  >
    <Section1 />

    <Grid position="relative" container flexDirection="row" flexWrap="nowrap">
      <div style={{ overflow: "hidden", width: "60%", aspectRatio: "1" }}>

        <img src="images/landing1.jpeg" alt="bg1" style={{
          width: "140%",
          height: "100%",
          clipPath: "polygon(0% 0%, 60% 0%, 100% 100%, 0% 100%)",
          objectFit: "cover",
          filter: "grayscale(100%) contrast(50%)", 
          position: "relative",
          left: "-40%"
        }} />
      </div>

      <Grid
        container
        flexDirection="column"
        alignItems="center"
        style={{ marginTop: "calc(-10px + 4vw)", marginRight: "30px", width: "50%", fontWeight: "bold" }}>

        <Typography className="content-text">
          Proinspect.com e платформа за независим финансов одит мениджмънт на фирми, който е съобразен с нуждите на всеки одитор.
          Онлайн платформата разполага с  всички документи, необходими за одитния процес.
          В системата са имплементирани шаблони, които представляват стандартните видове тестове.
        </Typography>

        <hr className="thick-hr" style={{ width: "calc(50px + 10vw)", margin: "3vw auto 0", backgroundColor: "black" }} />

      </Grid>
    </Grid>

    <Grid
      container
      flexDirection="column"
      flexWrap="nowrap"
      backgroundColor="#383838"
      alignItems="center"
      gap="1vw"
      className="rect section3">
      <Typography className="content-header large header-secondary" variant="h3" fontWeight="bold">
        Нашата мисия
      </Typography>

      <hr className="thick-hr" style={{ backgroundColor: "white", margin: "0 auto" }} />

      <Typography color="white" textAlign="center" className="content-text">
        Ние сме екип, който вярва в това, което прави. Нашата мисия е да създадем подобрен, качествен и ефективен начин на работа за всеки
        одитен процес като се насочихме върху онлайн услуги, предоставящи възможност за безхартиен одит.
      </Typography>
    </Grid>

    <OurGoals />

    <Advantages />

    <Grid
      container
      flexDirection="column"
      backgroundColor="white"
      alignItems="center"
      gap={3}
    >
      <hr className="thick-hr" style={{ backgroundColor: "black", margin: "0 auto" }} />

      <Typography variant="h3" fontWeight="bold" margin="1em 0" className="content-header large" style={{ color: "black" }}>
        Решението
      </Typography>

      <Box
        className="digitalization-section"
      >
        <Stack backgroundColor="#383838" padding="2em" gap={3} marginTop="10vw" width="60%" zIndex={1} height="auto" alignSelf="start">

          <Typography variant="h3" fontWeight="bold" color="white">
            Конвертирахме процесите на хартия в цифров формат
          </Typography>

          <hr className="thick-hr" style={{ backgroundColor: "white", margin: "0 auto", width: "40vw" }} />

          <Typography color="white" className="content-text">
            Създадохме лесен и автоматизиран процес за всеки, който цени времето си!
          </Typography>
        </Stack>

        <img src="images/landing2.jpg" />
      </Box>
    </Grid>

    <Button variant="contained" className="app-link-button">
      <Link to="/audits">
        Започни работа в Proinspect
        <ArrowRightIcon style={{ verticalAlign: "middle", marginLeft: "0.25em" }} />
      </Link>
    </Button>
  </Grid>
};
