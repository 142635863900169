import { Alert, Snackbar } from "@mui/material";
import { createContext, useContext, useState } from "react";


export function useNotification() {
    const [isOpen, setIsOpen] = useState(false)
    const [msg, setMsg] = useState("")
    const [severity, setSeverity] = useState(null)

    return {
        isOpen, setIsOpen,
        msg, setMsg,
        severity, setSeverity
    }
}

export function Notification({ snackbarProps, notification }) {
    return <Snackbar
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
        }}
        open={notification.isOpen}
        autoHideDuration={3000}
        {...snackbarProps}
        onClose={() => notification.setIsOpen(false)}>

        <Alert severity={notification.severity || "success"} variant="filled">
            {notification.msg}
        </Alert>
    </Snackbar>
}

const globalNotificationContext = createContext(null)

export function GlobalNotificationWrapper({ children }) {
    const notification = useNotification()

    return <globalNotificationContext.Provider
        value={notification}
    >
        {children}

        <Notification notification={notification} />
    </globalNotificationContext.Provider>
}

export const useGlobalNotification = () => useContext(globalNotificationContext)