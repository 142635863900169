import { Box, Paper, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react'
import { Datagrid, FilterForm, List, ResourceContextProvider, TextField, TextInput } from 'react-admin'
import MyUrlField from './UrlCustomField';
import { Link } from 'react-router-dom';
import AdfScannerIcon from '@mui/icons-material/AdfScanner';

const libraryFilters = [
  <TextInput label="Търси" variant='outlined' source="search" alwaysOn sx={{marginLeft:"5px"}} />,
];

const ListToolbar = () => (
  
  <Box>
     <Stack direction="row" justifyContent="space-between">
        <FilterForm filters={libraryFilters} />
    </Stack>
  </Box>
)

export const MyDocs = (props) => {

  return (
    <Paper sx={{width:"90%", alignSelf:"center", margin:"10px"}}>
     <Typography sx={{ fontSize: "18px", margin: "10px 0px 10px 20px" }}>Начало / Библиотека / Моите Документи</Typography>
    <Box sx={{ margin: "10px 0px 10px 20px", display:"flex", alignItems:"center", gap:"5px" }}>
    <AdfScannerIcon color='disabled' fontSize='large'/><Link to="/library" className='exLink'> Библиотека</Link>
    </Box>
    <ResourceContextProvider value={`document/all?favourites=true&`}>
    <List 
    sx={{ width: "100%", alignSelf: "center", boxShadow:"none" }} exporter={false} actions={null} pagination={false}>
 <ListToolbar/>
    <Datagrid
    {...props}
    bulkActionButtons={false}>
      <TextField source="name" label="Име на документ"/>
      <TextField source="" label="Вид"/>
      <TextField source="description" label="Описание"/>
      <MyUrlField target="_blank" source="filename" label="Преглед"/>
    </Datagrid>
  </List>
  </ResourceContextProvider>
  </Paper>
  )
}