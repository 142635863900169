import { Menu, useSidebarState } from "react-admin";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HomeIcon from '@mui/icons-material/Home';
import { createTheme, ThemeProvider } from "@mui/material";
import { SideNavigationItem } from "./MyMenuList";


import ListAltIcon from '@mui/icons-material/ListAlt';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import InboxIcon from '@mui/icons-material/Inbox';
import GroupIcon from '@mui/icons-material/Group';
import QuizIcon from '@mui/icons-material/Quiz';
import LinkIcon from "@mui/icons-material/Link"
import DropDown from "./DropDown"


import "./AppMenu.scss";
import { useLocation, useMatch } from "react-router-dom";
import { useEffect, useState } from "react";
import { getStages } from "../apiUtils/requests";

import {useStagesContext} from "../utils/stagesContext"
import { useAuth } from "../apiUtils/keycloak";

export const AppMenu = () => {
  // sx={{ marginTop: "0px", marginBottom: 0, width: "100px" }}
  const [isSidebarOpen] = useSidebarState();

  const location = useLocation()

  const {token} = useAuth()

  const isDropdownOpen = location.pathname.includes("/audits/") && isSidebarOpen && token
  const baseUrl = location.pathname.slice(1, location.pathname.lastIndexOf("/"))

  const {stages, setStages} = useStagesContext()

  useEffect(() => {
    getStages().then(resp => {
      if(!resp.data){
        return
      }

      setStages(resp.data?.stages)
    })
  }, [])

  const isSubmenuOpen = useMatch(`/:a/:b/:c`)

  return (
    <ThemeProvider
      theme={createTheme({
        palette: { primary: { main: "#d1005e" } },
        components: {
          MuiMenuItem: {
            styleOverrides: {
              root: {
                whiteSpace: 'normal',
                "&.RaMenuItemLink-active": {
                  backgroundColor: "#fafafb",
                  color: '#00a9e0'
                }
              }
            }
          }
        }
      })}
    >
      <Menu className="app-menu">
        <SideNavigationItem title="Начало" icon={HomeIcon} url="/" />
        <SideNavigationItem title="Нов Одит" url="/new-audit" icon={AddCircleOutlineIcon} />
        <div>
          <SideNavigationItem title="Одити" url="/audits" icon={ListAltIcon} notSelected={isSubmenuOpen}/>
          <DropDown baseUrl={baseUrl} submenus={stages} isOpen={isDropdownOpen} />
        </div>

        <SideNavigationItem title="Финансови години" url="/fiscal-years" icon={SignalCellularAltIcon} />
        <SideNavigationItem title="ЕГО" url="/annual-report" icon={InboxIcon} />
        <SideNavigationItem title="Потребители" url="/profiles" icon={GroupIcon} />
        <SideNavigationItem title="Библиотека" url="/library" icon={InboxIcon} />
        <SideNavigationItem title="Настройки" url="/settings" icon={ListAltIcon} />
        <SideNavigationItem title="Полезни връзки" url="/helpful-links" icon={LinkIcon} />
      </Menu>
    </ThemeProvider>
  );
};
