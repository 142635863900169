import { AppBar } from "react-admin";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { Box } from "@mui/system";
import { Toolbar } from "@mui/material";
import { useState, useEffect } from "react";
import { AppBar as MuiAppBar } from "@mui/material"
import { Link } from "react-router-dom";
import { AccountSection } from "./AccountSection";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "#ffffff",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const options = {
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};


function getCurrentDate() {
  return new Date().toLocaleString("bg-BG", options)
}

function useLiveDate() {
  const [date, setDate] = useState(getCurrentDate())


  useEffect(() => {
    const interval = setInterval(() => {
      setDate(getCurrentDate());
    }, 60 * 1000);

    return () => clearInterval(interval)
  }, [])

  return date
}

function SearchField() {
  // To do: make controlled
  return <Search>
    <SearchIconWrapper>
      <SearchIcon />
    </SearchIconWrapper>
    <StyledInputBase inputProps={{ "aria-label": "search" }} />
  </Search>
}



export const MyAppBar = (props) => {
  return (
    <AppBar
      {...props}
      elevation={0}
      sx={{
        width: "100%",
        backgroundColor: "#212B34",
        color: "#ffffff",
        display: "flex",
      }}
    >
      <img src="images/logo.png" alt="logo" sx={{ width: "20px", height: "30px" }} />

      <Box sx={{ display: "flex", gap: "20px", marginLeft: "auto" }}>
        <SearchField />
        <AccountSection />
      </Box>
    </AppBar>
  );
};

export function LandingPageAppBar(props) {
  return (
    <MuiAppBar
      {...props}
      elevation={0}
      sx={{
        width: "100%",
        backgroundColor: "#212B34",
        color: "#ffffff",
        display: "flex",
      }}
    >
      <Toolbar variant="dense">
        <Link to="audits" style={{ color: "white", marginRight: "2em", display: "flex", alignItems: "center", gap: "0.5em" }}>
          <img src="images/logo.png" alt="logo" sx={{ width: "20px", height: "30px" }} />
        </Link>

        <Box sx={{ display: "flex", gap: "20px", marginLeft: "auto" }}>
          <SearchField />
          <AccountSection />
        </Box>
      </Toolbar>
    </MuiAppBar>
  );
}
