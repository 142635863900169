import { Layout } from 'react-admin';
import { AppMenu } from './AppMenu';
import { LandingPageAppBar, MyAppBar } from './NavBar';
import AppSidebar from './Sidebar';
import "./AppLayout.scss"
import { Route, Routes } from 'react-router-dom';

export function AppLayout(props) {
    return <Layout
        {...props}
        style={{ width: "100vw", minWidth: "auto", height: "100vh" }}
        appBar={MyAppBar}
        menu={AppMenu} />
}

export function LandingPageLayout(props) {
    return <Layout
        {...props}
        sidebar={"div"}
        menu="div"
        style={{ width: "100vw", minWidth: "auto", height: "100vh" }}
        appBar={LandingPageAppBar} />
}

export function LayoutsRouter(props)
{
    return <Routes>
        <Route path='/' element={<LandingPageLayout {...props} />} />
        <Route path='/*' element={<AppLayout {...props} />} />
    </Routes>
}