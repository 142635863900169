
import { useEffect, useMemo, useState } from "react";
import {
    Grid
} from "@mui/material";
import { auditContext, useAuditContext } from "../auditContext";
import MainStageContent from "./MainStageContent";
import StageLinks from "./StageLinks";
import { getTestTables } from "../../../apiUtils/requests";


export function StageContent({ links, openLinkIndex, setOpenLinkIndex, stageName }) {
    const [tables, setTables] = useState()

    const auditContextValue = useAuditContext()

    const linkId = links?.[openLinkIndex]?.id


    useEffect(() => {
        if (!linkId) {
            return
        }

        auditContextValue.setAwaitedResponsesCount(c => c + 1)
        getTestTables(linkId, auditContextValue.auditId).then(resp => {
            auditContextValue.setAwaitedResponsesCount(c => c - 1)

            if (!resp.data) {
                return
            }

            setTables(resp.data.tables)
        })
    }, [linkId])


    const contextValue = useMemo(() => ({
        ...auditContextValue,
        tables
    }), [auditContextValue, tables])

    return <auditContext.Provider value={contextValue}>
        <Grid
            container
            flexWrap="nowrap"
            p="5px"
            gap={2}
            height={0}
            flexGrow={1}
            wrap="nowrap"
        >
            <StageLinks
                title={stageName}
                links={links}
                openStep={setOpenLinkIndex}
                currentMenuOptionIndex={openLinkIndex} />

            <MainStageContent
                currentMenuOptionName={links?.[openLinkIndex]?.name} />
        </Grid>
    </auditContext.Provider>
}



