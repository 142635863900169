import { createContext, useContext, useMemo, useState } from "react";

const context = createContext({
    stages: [],
    setStages: () => {}
})

export function StagesContextProvider({children}){
    const [stages, setStages] = useState()

    const value = useMemo(() => ({stages, setStages}), [stages])

    return <context.Provider value={value}>
        {children}
    </context.Provider>
}

export const useStagesContext = () => useContext(context)