import { IconButton, Paper, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { changePass, getCurrentAuth } from '../apiUtils'
import "./Settings.css"
import { useEffect, useState } from "react";
import { Button } from 'react-admin'
import VisibilityIcon from '@mui/icons-material/Visibility';

function ProfileDetails() {
    const [personalDetails, setPersonalDetails] = useState("")

    useEffect(() => {
        const fetchProfile = async () => {
            const response = await getCurrentAuth();
            setPersonalDetails(response)
            console.log(response)
        }
        fetchProfile();
    }, [])

    return <Box
        sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        <Typography sx={{ color: "#00a9e0", fontWeight: "500", marginBottom: "20px" }}>Лични данни</Typography>
        <Typography><b>Име:</b> {personalDetails.name} {personalDetails.lastName}</Typography>
        <Typography><b>Имейл:</b> {personalDetails.email} </Typography>
        {personalDetails.profiles?.map((profile) => <Typography><b>Тип профил:</b> {profile?.role === "AUDITOR" ? "Главен Одитор" : "Асистент Одитор"}</Typography>)}
        <Typography><b>Дата на регистрация: </b></Typography>
    </Box>
}

function ChangePassword() {
    const [password, setPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [repeatNewPassword, setRepeatNewPassword] = useState("")
    const [passwordShown, setPasswordShown] = useState(false);
    const [errorMessage, setErrorMessage] = useState("")


    const togglePasswordOld = () => {
        setPasswordShown(!passwordShown);
    };

    const togglePasswordNew = () => {
        setPasswordShown(!passwordShown);
    };

    const togglePasswordRepeatNew = () => {
        setPasswordShown(!passwordShown);
    };

    const setPass = () => {
        const body = {
            password: password,
            newPassword: newPassword
        }
        changePass(body)
    }

    const handleRepeatePassChange = (e) => {
        const repeatePassValue = e.target.value
        setRepeatNewPassword(repeatePassValue);

        if (repeatePassValue !== newPassword) {
            setErrorMessage("Паролата не съвпада")
        } else if (repeatePassValue === newPassword) {
            setErrorMessage("")
        }
        console.log(repeatePassValue)

    }

    const handleNewPassChange = (e) => {
        const currentNewPass = e.target.value
        setNewPassword(currentNewPass);

        if (currentNewPass !== repeatNewPassword) {
            setErrorMessage("Паролата не съвпада")
        } else if (currentNewPass === repeatNewPassword) {
            setErrorMessage("")
        }
        console.log(currentNewPass)
    }

    return <Box
        sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        <Typography sx={{ color: "#00a9e0", fontWeight: "500", marginBottom: "20px" }}>Сигурност</Typography>
        <Typography>Смяна на парола</Typography>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "5px", alignItems: "center" }} >
            <TextField variant='outlined' type={passwordShown ? "text" : "password"} id="password" label="парола" value={password} onChange={(e) => setPassword(e.target.value)}></TextField>
            <IconButton onClick={togglePasswordOld}><VisibilityIcon /></IconButton>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "5px", alignItems: "center" }}>
            <TextField variant='outlined' type={passwordShown ? "text" : "password"} value={newPassword} label="нова парола" onChange={(e) => handleNewPassChange(e)}></TextField>
            <IconButton onClick={togglePasswordNew}><VisibilityIcon /></IconButton>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "5px", alignItems: "center" }}>
            <TextField variant='outlined' type={passwordShown ? "text" : "password"} value={repeatNewPassword} label="повтори нова парола" onChange={(e) => handleRepeatePassChange(e)}></TextField>
            <IconButton onClick={togglePasswordRepeatNew}><VisibilityIcon /></IconButton>
        </Box>
        <Box sx={{ margin: "0px 0px 20px 0px" }}>
            <Typography
                sx={{
                    position: "absolute",
                    fontSize: "12px",
                    color: "#d32f2f",
                }}
            >
                {errorMessage}
            </Typography>
        </Box>
        <Button
            size="medium"
            variant="contained"
            sx={{ width: "225px" }}
            onClick={setPass}
        >Потвърди</Button>
    </Box>
}

export const Settings = () => {
    return (
        <Paper
            elevation={0}
            variant="outlined"
            square={false}
            className="paper-container"
            sx={{
                width: "90%",
                height: "90vh",
                alignSelf: "center",
                alignItems: "center",
                margin: "10px",
                padding: "1em",
                display: "flex",
                flexDirection: "column"
            }}>

            <Box sx={{ width: "90%", alignSelf: "center", margin: "10px" }}>
                <Typography sx={{ fontSize: "18px", margin: "20px 0px 10px 20px" }}>
                    Начало / Настройки / Данни за потребителя
                </Typography>
            </Box>
            <Box
                sx={{ width: "100%", maxWidth: "600px", display: "flex", flexDirection: "column", marginTop: "10%", flexGrow: 1 }}>
                <Box
                    sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>

                    <ProfileDetails />

                    <ChangePassword />
                </Box>

            </Box>
                <img
                    src="images/logo.png"
                    alt="logo"
                    className="logo"
                    style={{ margin: "auto auto 3em auto" }}
                    width={200} />
        </Paper>
    )
}
