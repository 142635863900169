import './Dropdown.scss';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTableContext } from '../../ViewAudit/tableContext';
import { SelectBoolInapplicable } from '../../../../utils/SelectBoolInapplicable';

const Dropdown = ({ elementData: cell }) => {
    const { updateCell } = useTableContext()

    if (cell.options.includes("Неприложимо")) {
        return <SelectBoolInapplicable
            {...cell}
            onChange={value => updateCell({
                id: cell.id,
                value
            })} />
    }

    return <FormControl
        variant="outlined"
        fullWidth
        margin="none"
        size="small"
        style={{ flexBasis: 0, flexShrink: 1, flexGrow: 1, marginTop: "5px" }}>
        <InputLabel id="select-label">{cell.label}</InputLabel>
        <Select
            placeholder='Избери'
            labelId="select-label"
            value={cell.value || ""}
            label={cell.label}
            onChange={e => updateCell({ id: cell.id, value: e.target.value })}>

            {cell.options?.map(option => <MenuItem value={option} key={option}>
                {option}
            </MenuItem>)}
        </Select>
    </FormControl>
};

export default Dropdown;
