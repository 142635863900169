import Area from './Types/Area';
import CheckBoxComponent from './Types/Checkbox';
import Consents from './Types/Consents';
import Dropdown from './Types/Dropdown';
import Flow from './Types/Flow';
import { Header } from './Types/Header';
import Hint from './Types/Hint';
import Input from './Types/Input';
import Radio from './Types/Radio';
import Section from './Types/Section';
import { TableCell } from './Types/Table';
import { TextField } from './Types/Text';
import UploadFile from './Types/UploadFile';
import { fieldsTypes } from './fieldTypes';

import "./general.scss"

function FieldSwitch({elementData: field, sectionData, sectionDataFields}) {
    const commonProps = {
        elementData: field
    }

    switch (field.type) {
        case fieldsTypes.TEXT:
            return <TextField {...commonProps} />
        case fieldsTypes.NEWLINE:
            return <br />;
        case fieldsTypes.HORIZONTAL_LINE:
            return <hr />;
        case fieldsTypes.INPUT:
            return <Input {...commonProps}/>;
        case fieldsTypes.RADIO:
            return <Radio {...commonProps} />;
        case fieldsTypes.AREA:
            return <Area {...commonProps} />;
        case fieldsTypes.CONSENT:
            return <Consents {...commonProps} />;
        case fieldsTypes.CHECKBOX:
            return <CheckBoxComponent {...commonProps} />;
        case fieldsTypes.DROPDOWN:
            return <Dropdown {...commonProps} />;
        case fieldsTypes.FLOW:
            return <Flow {...commonProps} />;
        case fieldsTypes.BACK:
            return <Flow {...commonProps} />;
        case fieldsTypes.DOCUMENT:
            return <UploadFile {...commonProps} />;
        case fieldsTypes.HINT:
            return <Hint {...commonProps} />;
        case fieldsTypes.SECTION:
            return <Section {...commonProps}/>;
        case fieldsTypes.TABLE:
        case fieldsTypes.SUB_TABLE:
            return <TableCell {...commonProps} />;
        case fieldsTypes.HEADER:
            return <Header {...commonProps} />;
        case fieldsTypes.ROW:
            return <Section {...commonProps} />;
        default:
            return null;
    }
}

export default FieldSwitch;
