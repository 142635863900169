import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { backendUrl } from '../../../../apiUtils/urls';
import { useAuditContext } from '../../auditContext';
// import { useKeycloak } from '@react-keycloak/web';
import ExpandMore from "@mui/icons-material/ExpandMore"
import InfoIcon from "@mui/icons-material/InfoOutlined"
import { useAuth as useKeycloak } from '../../../../apiUtils/keycloak';
import { useIdentificationContext } from '../identificationContext';

import "./index.scss"

export function CompanyDetailsWrapper({ }) {
  const authHandler = useKeycloak()
  const { data: companyDetails, setData: setCompanyDetails, isLoaded, setIsLoaded } = useIdentificationContext();
  const tokenAuth = authHandler.token;
  const { auditId } = useAuditContext()

  useEffect(() => {
    if (isLoaded) {
      return
    }

    const config = {
      headers: {
        authorization: `Bearer ${tokenAuth}`,
      },
    };
    axios
      .get(`${backendUrl}/acceptance/identification?auditId=${auditId}`, config)
      .then((response) => setCompanyDetails(response.data))
      .finally(() => console.log("Loaded") || setIsLoaded(true));
  }, [auditId, tokenAuth, companyDetails]);

  return <CompanyDetails
    isLoaded={isLoaded}
    companyDetails={companyDetails} />
}

export function CompanyDetails({ companyDetails, open, onMount, isLoaded }) {
  useEffect(() => {
    onMount?.()
  }, [])

  return <Accordion
    className='top-section'
    id='company-details'
    expanded={open}
    TransitionProps={open ? { timeout: 0 } : {}}
    variant="outlined" >

    <AccordionSummary
      expandIcon={<ExpandMore />}>
      <Typography display="flex" alignItems="flex-end" gap="1em">
        <InfoIcon /> Данни за фирма/ Общ статус
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Box className="company-details-content">
        {isLoaded ? null :
          <CircularProgress
            sx={{ position: "absolute", marginTop: "8%", marginLeft: "20%" }}
            color="secondary"
          />
        }

        <p className="text1">
          <b>Име:</b> {companyDetails?.companyName}
        </p>
        <p className="text1">
          <b>ЕИК:</b> {companyDetails?.companyEik}
        </p>
        <p className="text1">
          <b>Правна форма:</b> {companyDetails?.companyType}
        </p>
        <p className="text1">
          <b>Aдрес на управление:</b> {companyDetails?.companyAddress}
        </p>
        <p className="text1">
          <b>Предмет на дейност:</b> {companyDetails?.companyActivity}
        </p>
        <p className="text1">
          <b>Представители:</b> {companyDetails?.companyRepresentatives}
        </p>
      </Box>
    </AccordionDetails>
  </Accordion>
}
