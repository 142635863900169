export const HYPERLINKS_INFO = [
    [
        { href: 'https://www.registryagency.bg/bg/registri/targovski-registar/', title: 'Търговски регистър и регистър на ЮЛНЦ на Република България' },
        { href: 'https://nra.bg/wps/portal/nra/nachalo', title: 'Национална агенция по приходите' },
        { href: 'https://www.dans.bg/', title: 'Държавна агенция „Национална сигурност” ' },
        { href: 'https://www.fsc.bg/', title: 'Комисия за финансов надзор - регистри и справки' },
        { href: 'https://www.bnb.bg/', title: 'Българска Народна Банка' },
        { href: 'https://www.minfin.bg/bg/2', title: 'Министерство на финансите - Република България' },
        { href: 'https://www.bse-sofia.bg/bg/', title: 'Българска фондова борса' },
        { href: 'https://mvr.bg/%D0%B5%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%BD%D0%B8-%D1%83%D1%81%D0%BB%D1%83%D0%B3%D0%B8/%D0%B5%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%BD%D0%B8-%D1%83%D1%81%D0%BB%D1%83%D0%B3%D0%B8-%D0%B8-%D1%81%D0%BF%D1%80%D0%B0%D0%B2%D0%BA%D0%B8/%D1%81%D0%BF%D1%80%D0%B0%D0%B2%D0%BA%D0%B0-%D0%B7%D0%B0-%D0%B2%D0%B0%D0%BB%D0%B8%D0%B4%D0%BD%D0%BE%D1%81%D1%82-%D0%BD%D0%B0-%D0%B1%D1%8A%D0%BB%D0%B3%D0%B0%D1%80%D1%81%D0%BA%D0%B8-%D0%BB%D0%B8%D1%87%D0%BD%D0%B8-%D0%B4%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D0%B8', title: 'Справка за български лични документи' },
        { href: 'https://www.nsi.bg/bg', title: 'Национален статистически инстритут' },
        { href: 'https://ngo.mjs.bg/', title: 'Портал за електронни административни услуги за юридическите лица с нестопанска цел в обществена полза' }
    ],
    [
        { href: 'https://register.caciaf.bg/', title: 'Регистър на лица, заемащи висши публични длъжности' },
        { href: 'https://bar-register.bg/', title: 'Регистри на българска адвокатура' },
        { href: 'https://nacid.bg/bg/register_RND/', title: 'Регистър за научните организации и институти в Република България” ' },
        { href: 'https://nra.bg/wps/portal/nra/registers-i-spisuci/Spisuci/spisuk.visokoriskovi.durjavi.ZMIP', title: 'Регистър на държавите, които не прилагат или прилагат непълно международните стандарти' },
        { href: 'https://data.europa.eu/data/datasets/consolidated-list-of-persons-groups-and-entities-subject-to-eu-financial-sanctions?locale=bg', title: 'Консолидиран списък на лица във връзка със ЗМФТ' },
        { href: 'https://portal.registryagency.bg/CR/services/222', title: 'Търговски регистри на действителните собственици' },
        { href: '', title: 'Държави, които имат достъпни регистри на действителните собственици' },
        { href: 'https://nra.bg/wps/portal/nra/registers-i-spisuci/Spisuci/spisuk.visokoriskovi.durjavi.ZMIP', title: 'Държави от така наречените "черен" и "сив" списък на ЕС' },
        { href: 'https://bg.wikipedia.org/wiki/%D0%A0%D0%B0%D0%B1%D0%BE%D1%82%D0%BD%D0%B0_%D0%B3%D1%80%D1%83%D0%BF%D0%B0_%D0%B7%D0%B0_%D1%84%D0%B8%D0%BD%D0%B0%D0%BD%D1%81%D0%BE%D0%B2%D0%BE_%D0%B4%D0%B5%D0%B9%D1%81%D1%82%D0%B2%D0%B8%D0%B5', title: 'FAFT - работна група за финансово действие' },
    ],
    [
        { href: 'https://www.minfin.bg/bg/1390', title: 'Международен валутен фонд - офшорни финансови центрове' },
        { href: 'https://nra.bg/wps/portal/nra/taxes/dds-pr-sdelki-v-ES', title: 'Списъци на ЕС за данъчни цели' },
        { href: 'https://www.minfin.bg/bg/news/11388', title: 'Санкции на МФ-САЩ (OFAC)' },
        { href: 'https://data.europa.eu/data/datasets/consolidated-list-of-persons-groups-and-entities-subject-to-eu-financial-sanctions?locale=bg', title: 'Консолидиран списък на лица, обект на финансови санкции от странан на ЕС' },
        { href: 'https://nacid.bg/bg/register_RND/', title: 'Регистър за научната дейност ' },
        { href: 'https://www.fsc.bg/', title: 'Връзка 2' },
        { href: 'https://www.fsc.bg/', title: 'Връзка 3' },
        { href: 'https://www.fsc.bg/', title: 'Връзка 4' },
        { href: 'https://www.fsc.bg/', title: 'Връзка 5' },
        { href: 'https://www.fsc.bg/', title: 'Връзка 6' },
        { href: 'https://www.fsc.bg/', title: 'Връзка 7' },
        { href: 'https://www.fsc.bg/', title: 'Връзка 8' },
    ]
];