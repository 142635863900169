import { Menu, useSidebarState } from "react-admin";
import { Link } from "react-router-dom";
// import DropDown from "./DropDown.js";
// import { useState, useEffect } from "react";
// import { useLocation, useParams } from "react-router-dom";

// const MyMenuListItem = ({ item }) => {
//   const [isSidebarOpen] = useSidebarState();

//   const location = useLocation()

//   const isDropdownOpen = location.pathname.includes("/audits/") && isSidebarOpen
//   const baseUrl = location.pathname.slice(1, location.pathname.lastIndexOf("/"))

//   return item.submenu ? (
//     <>
//       <Menu.Item
//         to="/audits"
//         aria-expanded={isDropdownOpen}
//         primaryText={item.title}
//         leftIcon={item.icon}
//       />
//       <DropDown baseUrl={baseUrl} submenus={item.submenu} isOpen={isDropdownOpen} />
//     </>
//   ) : (
//     )
// };

export function SideNavigationItem(item) {
  return <Menu.Item
    className={item.notSelected ? "not-selected" : ""}
    selected={item.selected}
    to={item.url}
    primaryText={item.title}
    leftIcon={<item.icon className="menu-item-icon" />}
  />
}