import { Button, Tooltip } from "@mui/material";
import { useTableContext } from "./tableContext";
import SaveIcon from "@mui/icons-material/Save"

export function SaveButton() {
  const { selectedTable, saveTable} = useTableContext()

  const button = <Button
    onClick={saveTable}
    style={{ marginLeft: selectedTable.savable ? 10 : 0 }}
    variant="contained"
    startIcon={<SaveIcon />}
    disabled={!selectedTable.savable}
    size="small">
    Запази
  </Button>

  if (!selectedTable.savable) {
    return <Tooltip title="Тази таблица се запазва автоматично" style={{marginLeft: "10px"}}>
      <div>
        {button}
      </div>
    </Tooltip>
  }

  

  return button
}