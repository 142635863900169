import * as React from "react";
import { useRecordContext } from "react-admin";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";

const ProfileField = () => {
  const record = useRecordContext();
  const profile = record;
  console.log(profile)

  return record ? (
    <div>
      <Box sx={{ display: "flex", justifyContent: "center", padding: "5px" }}>
        <Link
          state= { profile }
          to={{
            pathname: `/profiles/${record.id}/profile-details`,
          }}
          className="link"
        >
          {record.name} {record.lastName}
        </Link>
      </Box>
    </div>
  ) : null;
};

export default ProfileField;
