import { Button, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import KeyIcon from '@mui/icons-material/Key';
import {useAuth as useKeycloak} from '../apiUtils/keycloak';
// import { useKeycloak } from '@react-keycloak/web';

export const SessionExpired = () => {
  const authHandler = useKeycloak()

  return (
    <Paper
      elevation={0}
      variant="outlined"
      square={false}
      className="paper-container"
      sx={{ width: "80%", height: "90vh", alignSelf: "center", margin: "10px", display: "flex", flexDirection: "column"}}>
        <Box  sx={{ width: "100%", display: "flex", flexDirection:"column", alignItems: "center", gap:"30px", margin:"auto"}}>
        <KeyIcon color='secondary' sx={{transform: "scale(3)"}}/>
        <Typography variant="h5">Съжаляваме, но сесията Ви изтече</Typography>
        <Typography variant='h6'>За да видите съдържанието е необходимо да влезете в профила си.</Typography>
        <Button size="medium" variant="contained" color="secondary" sx={{width:"200px"}} onClick={() => authHandler.login()}>Вход</Button>
        </Box>
    </Paper>
  )
}
