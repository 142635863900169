import { createContext, useCallback, useContext, useMemo, useState } from "react";
import { getTableRow, getTableRows } from "../../../../apiUtils/requests";
import { useAuditContext } from "../../auditContext";
import { useInfiniteQuery, useQuery } from "react-query";
import { queryClient } from "../../../../utils/reactQuery";
import { fieldsTypes } from "../../fields/fieldTypes";
import { useSaveTable } from "./useSaveTable";
import { useUpdateCell } from "./useUpdateCell";

const tableContext = createContext({
    rows: [],
    fetchNextRows: async (count) => { },
    selectedTable: null,
    selectedTabIndex: 0,
    setSelectedTabIndex: (index) => { },
    totalRowsCount: 0,
    updateCell: ({ id, value }) => { },
    addRow: (row) => { },
    setRows: (rows) => { },
    saveTable: async () => { }
})



function useAddRow(setRows) {
    function addRow(row) {
        setRows(rows => [
            ...rows,
            row
        ])
    }

    return useCallback(addRow, [setRows])
}




async function fetchFullRow(row, tableId, auditId, signal) {
    async function getCompleteTableRows(tableId) {
        console.log("Fetch nested rows for tableId: ", tableId)
        const rows = await getTableRows(tableId, auditId)
        if (!rows.data) {
            return
        }

        const completeRows = await Promise.all(rows.data?.rows?.map(async row => {
            const res = await fetchRow({ id: row }, tableId)
            return {
                ...res,
                isLoaded: true
            }
        }))

        console.log("Nested rows for tableId ", tableId, ": ", completeRows)


        return completeRows
    }

    async function fetchRow(row, tableId) {
        console.log("Fetch row: ", row, "in table: ", tableId)
        const resp = await getTableRow(tableId, auditId, row.id, signal)
        const completeRow = resp.data
        if (!completeRow) {
            return {
                ...row,
                isLoaded: true
            }
        }

        const { row: _rowId, ...withoutExtra } = completeRow

        const completeCells = await Promise.all(completeRow.cells?.map(async cell => {
            if (cell.type !== fieldsTypes.SUB_TABLE && cell.type !== fieldsTypes.TABLE) {
                return cell
            }

            return {
                ...cell,
                rows: await getCompleteTableRows(cell.label)
            }
        }))

        return {
            ...row,
            ...withoutExtra,
            cells: completeCells,
            isLoaded: true
        }
    }

    return fetchRow(row, tableId)

}

export function TableContextProvider({ children, fetchTable: sendTableRequest = null }) {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0)

    const auditContextValue = useAuditContext()

    const selectedTable = auditContextValue?.tables?.[selectedTabIndex]
    const tableId = selectedTable?.id

    const tableRowsQuery = useQuery(
        ["tableRows", auditContextValue.auditId, tableId, sendTableRequest],
        fetchTable,
        {
            refetchOnWindowFocus: false
        }
    )

    const completeTable = useMemo(() => {
        const { rows, tableId, ...rest } = tableRowsQuery.data || {}
        return {
            tableId,
            ...rest,
            ...selectedTable
        }
    }, [tableRowsQuery.data, selectedTable])

    const completeRowsKey = ["completeTableRows", auditContextValue.auditId, tableId, tableRowsQuery.data]

    const completeTableRowsQuery = useInfiniteQuery(
        completeRowsKey,
        fetchNextRows,
        {
            refetchOnWindowFocus: false,
            cacheTime: 0
        }
    )

    const loadedRows = useMemo(() => completeTableRowsQuery.data?.pages?.flat(), [completeTableRowsQuery.data?.pages])
    const loadedRowsCount = loadedRows?.length || 0

    async function fetchNextRows({ pageParam = { skip: 0, count: 10 }, signal }) {
        console.log("Fetch next rows. For now loaded: ", loadedRows, ". All rows: ", tableRowsQuery.data)

        if (!tableRowsQuery.data) {
            // console.log("Unable to fetch rows")
            return []
        }

        const { count, skip = 0 } = pageParam

        console.log("Fetching from " + skip + " to " + (count ? skip + count : undefined) + ". pageParam is: ", pageParam)

        const res = await Promise.all(tableRowsQuery.data?.rows
            ?.slice(skip, count ? skip + count : undefined)
            .map(async (row) => fetchFullRow(row, tableRowsQuery.data.tableId, auditContextValue.auditId, signal))
        )

        console.log("Fetched rows: ", res)

        return res
    }

    async function onRequestNextRows(count) {
        // console.log("Request next rows. All rows: ", tableRowsQuery.data)
        await completeTableRowsQuery.fetchNextPage({ pageParam: { count, skip: loadedRowsCount } })
    }

    function setRows(update) {
        queryClient.setQueryData(completeRowsKey, (data) => {
            const res = ({
                pages: [update(data.pages?.flat())],
                pageParams: data.pageParams
            })

            console.log("Manually updated rows. Was: ", JSON.parse(JSON.stringify(data)), "Now: ", res)
            return res
        })
    }

    const { updateCell, updatedCells } = useUpdateCell(setRows, completeTable)
    const addRow = useAddRow(setRows)
    const saveTable = useSaveTable(updatedCells)

    if (!auditContextValue) {
        console.error("TableContextProvider must be wrapped in audit context provider!")
    }

    async function fetchTable() {
        if (!tableId && !sendTableRequest) {
            return
        }

        const resp = await (sendTableRequest ? sendTableRequest() : getTableRows(tableId, auditContextValue?.auditId))

        if (!resp.data) {
            return
        }

        const rowsIds = resp.data.rows

        const rows = rowsIds.map(id => ({
            id
        }))

        return {
            ...resp.data,
            rows
        }
    }

    const totalRowsCount = tableRowsQuery.data?.rows.length || 0

    const value = useMemo(() => ({
        rows: loadedRows,
        setRows,
        fetchNextRows: onRequestNextRows,
        selectedTable: completeTable,
        selectedTabIndex,
        setSelectedTabIndex,
        totalRowsCount,
        updateCell,
        addRow,
        saveTable
    }), [
        loadedRows,
        completeTable,
        selectedTabIndex,
        totalRowsCount,
        updateCell,
        addRow,
        saveTable
    ])

    return <tableContext.Provider value={value}>
        {children}
    </tableContext.Provider>
}

export const useTableContext = () => useContext(tableContext)