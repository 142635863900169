import { Admin, Resource, CustomRoutes } from 'react-admin';
import {
    LayoutsRouter,
} from './Layout/AppLayout';
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';
import NewAudit from './Pages/Audits/NewAudit';
import { Dashboard } from './Pages/Dashboard/Dashboard';
// import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";
// import keycloak from "./apiUtils/keycloak";
import { useAuth, AuthProvider, authTypes } from './apiUtils/keycloak';
import PrivateRoute from './PrivateRoute';
import { Profiles } from './Pages/Profiles/Profiles';
import { Library } from './Pages/Library';
import { useAppDataProvider } from './apiUtils/appDataProvider';
import { MyDocs } from './Pages/MyDocs';
import { Settings } from './Pages/Settings';
import { FiscalYears } from './Pages/FiscalYears';
import { ProfileDetails } from './Pages/Profiles/ProfileDetails';
import { AnnualReport } from './Pages/AnnualReport';
import AuditPage from './Pages/Audits/ViewAudit/AuditPage';
import AuditsTablePage from './Pages/Audits/AuditsTablePage';
import HelpfulLinksPage from './Pages/HelpfulLinks';
import { useAxiosController } from "./apiUtils";
import './Pages/Page.scss';
import React, { useMemo, useState } from 'react';
import { StagesContextProvider } from './utils/stagesContext';
import { queryClient } from './utils/reactQuery';
import { GlobalNotificationWrapper } from './utils/Notification';

function WithContexts({ children }) {
    return <StagesContextProvider>
        <HashRouter>
            <GlobalNotificationWrapper>

                <AuthProvider
                    authType={authTypes.KEYCLOAK}
                    settings={{
                        baseUrl: 'https://openid.finoid.eu/auth/',
                        realm: 'audit-app',
                        clientId: 'starting_client',
                    }}
                >
                    {children}
                </AuthProvider>
            </GlobalNotificationWrapper>
        </HashRouter>
    </StagesContextProvider>
}

function App() {
    const dataProvider = useAppDataProvider();
    useAxiosController()
    const authHandler = useAuth();

    if (!authHandler?.isAuthStatusChecked) {
        return null;
    }

    return <Admin
        layout={LayoutsRouter}
        dataProvider={dataProvider}
        queryClient={queryClient}
    >
        <Resource name="profiles" list={Profiles} />
        <CustomRoutes>
            <Route path="/" element={<Dashboard />} />
            <Route
                path="/new-audit"
                auth
                element={
                    <PrivateRoute>
                        <NewAudit />
                    </PrivateRoute>
                }
            />
            <Route
                path="/profiles"
                auth
                element={
                    <PrivateRoute>
                        <Profiles />
                    </PrivateRoute>
                }
            />
            <Route
                path="/library"
                auth
                element={
                    <PrivateRoute>
                        <Library />
                    </PrivateRoute>
                }
            />
            <Route
                path="/library/my-docs"
                auth
                element={
                    <PrivateRoute>
                        <MyDocs />
                    </PrivateRoute>
                }
            />
            <Route
                path="/annual-report"
                auth
                element={
                    <PrivateRoute>
                        <AnnualReport />
                    </PrivateRoute>
                }
            />
            <Route
                path="/settings"
                auth
                element={
                    <PrivateRoute>
                        <Settings />
                    </PrivateRoute>
                }
            />
            <Route
                path="/fiscal-years"
                auth
                element={
                    <PrivateRoute>
                        <FiscalYears />
                    </PrivateRoute>
                }
            />
            <Route
                path="/helpful-links"
                element={<HelpfulLinksPage />}
            />
            <Route
                path="/audits/:auditId/:stageId"
                auth
                element={
                    <PrivateRoute>
                        <AuditPage />
                    </PrivateRoute>
                }
            />
            <Route
                path="/audits"
                auth
                element={
                    <PrivateRoute>
                        <AuditsTablePage />
                    </PrivateRoute>
                }
            />

            {/* <Route path="/audits/planning/:id" element={<PrivateRoute><MainPageStage /></PrivateRoute>} /> */}
            <Route
                path="/profiles/:id/profile-details"
                element={
                    <PrivateRoute>
                        <ProfileDetails />
                    </PrivateRoute>
                }
            />
        </CustomRoutes>
    </Admin>
}


function AppWithContexts() {
    return <WithContexts>
        <App />
    </WithContexts >
}

export default AppWithContexts;
