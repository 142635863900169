import "./Section.scss";
import FieldSwitch from "../FieldSwitch";
import { Stack } from "@mui/material";
import { rowTypes } from "../rowTypes";
import { useTableContext } from "../../ViewAudit/tableContext";

function SectionCells({ row }) {
  const tableContext = useTableContext()
  if (row.cells !== tableContext.selectedTable.columnCount) {
    return <tr>
      <td colSpan="100%" className="full-width-cell">
        <Stack direction="row">
          {row.cells?.map(cell => <FieldSwitch key={cell.id} elementData={cell} />)}
        </Stack>
      </td>
    </tr>
  }

  return <tr>
    {row.cells?.map(cell => <td key={cell.id}>
      <FieldSwitch elementData={cell} />
    </td>)}
  </tr>
}

const Section = ({ elementData: row }) => {
  return <SectionCells row={row} />
};

export default Section;
