import * as React from "react";
import { useRecordContext } from "react-admin";
import { Box } from "@mui/system";
import { Link, useLocation } from "react-router-dom";
import "./AuditNameLink.css";
import { useStagesContext } from "../../utils/stagesContext";

function AuditNameLink() {
  const profile = useRecordContext();
  const {stages} = useStagesContext()
  const auditLink = `/audits/${profile.id}/${stages[0]?.id}`

  if (!profile) {
    return null
  }

  return <Box sx={{ display: "flex", justifyContent: "center", padding: "5px" }}>
    <Link
      state={profile}
      to={{
        pathname: auditLink,
      }}
      className="link"
    >
      {profile.name}{" "}
    </Link>
  </Box>
};

export default AuditNameLink;
