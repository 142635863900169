import { createContext, useContext, useRef, useState } from "react";
import { getAllYears } from "../apiUtils/index"

const context = createContext({
    getYears: () => console.error("Years context used outside the years provider!")
})

export function YearsContextProvider({ children }) {
    const [years, setYears] = useState()
    const isFetched = useRef(false)

    function fetchYears() {
        if (!isFetched.current) {
            isFetched.current = true
            getAllYears().then(resp => setYears(resp))
        }

        return years
    }

    return <context.Provider value={{ getYears: fetchYears }}>
        {children}
    </context.Provider>
}

export const useYears = () => useContext(context)