import axios from "axios";

import { backendUrl, formbuilderApiUrl, urls } from "./urls";
// import { useKeycloak } from "@react-keycloak/web";
import { useEffect } from "react";
import {useAuth } from "./keycloak";

// Should be only used once in the app
export function useAxiosController()
{
  const authHandler= useAuth()

  function resetAxiosRequestInterceptors()
  {
    axios.interceptors.request.clear()
    axios.interceptors.request.use(config => {

      if(!config.headers || config.headers.authorization === undefined)
      {
        config.headers.authorization = `Bearer ${authHandler.token}`
      }
    
      return config
    })
  }

  function resetAxiosResponseInterceptors()
  {
    axios.interceptors.response.clear()
    axios.interceptors.response.use(
      undefined,
      error => {
        console.log(error)
        if (error.response?.status === 401) {
          //keycloak.init({ onLoad: "login-required" })
        }
    
        return error
      }
    )
  }

  useEffect(() => {
    console.log("Keycloak updated: ", authHandler?.token)
    //alert(authHandler.token)
    resetAxiosRequestInterceptors()
    resetAxiosResponseInterceptors()
  }, [authHandler?.token])
}

// export function useAPI()
// {
//   const keycloak = useKeycloak()

  

//   return {
//     axios
//   }
// }






export async function postNewAudit(data) {
  await axios.post(`${backendUrl}/${urls.POST_AUDIT}`, data)
    .then((resp) => {
      console.log('Success:', resp.data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
}

export async function postNewYear(data) {
  try {
    const resp = await axios.post(`${backendUrl}/${urls.GET_POSTYEAR}`, data, {
      headers: {
        "content-type": "application/json"
      }
    });

    return resp.data
  } catch (error) {
    console.log(error);
  }
}

export async function addFavouriteDoc(data) {
  try {
    await axios(`${backendUrl}/${urls.ADD_FAVOURITE}`, data, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      }
    });
  } catch (error) {
    console.error('Error:', error);
  };
}


export async function getAllYears() {
  try {
    const resp = await axios(`${backendUrl}/${urls.GET_ALLYEARS}`);
    if (resp.status === 200) {
      return resp.data;
    }
  } catch (error) {
    console.log(error);
  }
}

export async function getCurrentAuth() {
  try {
    const resp = await axios(`${backendUrl}/auth/current`);
    if (resp.status === 200) {
      return await resp.data
    }
  } catch (error) {
    console.log(error);
  }
}

// export async function changePass(data) {

//   const tokenAuth = keycloak.token;

//     try {
//       const resp = await fetch(`${baseUrl}/changePassword`, {
//         method: "POST",
//         headers: {
//           "authorization" : `Bearer ${tokenAuth}`,
//           "content-type": "application/json"
//         },
//         body: JSON.stringify(data)
//       });
//       if (resp.status === 200) {
//         return await resp.json();
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   }

export async function changePass(data) {
  try {

    const resp = await axios.post(`${backendUrl}/changePassword`, data, {
      headers: {
        "content-type": "application/json"
      }
    })

    return resp.data
  }
  catch (error) {
    console.error('Error:', error);
  }
}