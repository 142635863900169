import * as React from "react";
import { useRecordContext } from "react-admin";
import { Button, Link } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { Box } from "@mui/system";
import { addFavouriteDoc } from "../apiUtils";
import { useLocation } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";

const MyUrlField = ({ source }) => {
  const [isFavorite, setIsFavorite] = React.useState();
  const location = useLocation();
  const record = useRecordContext();

  const data = {
    documentId: record.id,
    favourites: true,
  };

  const favoriteDoc = () => {
    setIsFavorite(!isFavorite);
    addFavouriteDoc(data);
  };
  return record ? (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <Box>
        <Link
          href={record[source]}
          sx={{ textDecoration: "none" }}
          target="_blank"
        >
          <FileDownloadOutlinedIcon />
        </Link>
      </Box>
      {location.pathname !== "/library/my-docs" && (
        <Button onClick={() => favoriteDoc(!isFavorite)}>
          {isFavorite ? <StarIcon /> : <StarBorderIcon />}
        </Button>
      )}
    </Box>
  ) : null;
};

export default MyUrlField;
