import { CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { useAuth as useKeycloak } from '../../../../apiUtils/keycloak';
import { backendUrl } from '../../../../apiUtils/urls';
// import { useKeycloak } from '@react-keycloak/web'; 

export const CommonStageInfo = () => {
  const authHandler = useKeycloak()
  const [companyDetails, setCompanyDetails] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { auditId } = useParams();
  const tokenAuth = authHandler?.token;
  useEffect(() => {
    setIsLoading(true);
    const config = {
      headers: {
        authorization: `Bearer ${tokenAuth}`,
      },
    };
    axios
      .get(`${backendUrl}/acceptance/identification?auditId=${auditId}`, config)
      .then((response) => setCompanyDetails(response))
      .finally(() => setIsLoading(false));
  }, [auditId, tokenAuth]);

  //console.log(companyDetails)

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      {isLoading && (
        <CircularProgress
          style={{ margin: "auto" }}
          color="secondary"
        />
      )}
      {!isLoading && <span></span>}
      {/* <Typography className="title2">
        Общо разбиране/ Разбиране на сектора
      </Typography> */}
      <p className="text1">
        <b>Клиент:</b> {companyDetails.data?.companyName}  {companyDetails.data?.companyType}
      </p>
      <p className="text1">
        <b>Година на одит:</b> {companyDetails.data?.yearOfAudit}
      </p>
      {/* <Typography className="title2">
        Общо разбиране на предприятието и неговата дейност
      </Typography>
      <Typography>Цел на процедурата: Да се получи общо разбиране на дейността на предприятието и да се актуализира информацията при настъпили промени</Typography>
      <p className="text1">
        <b>Основна дейност:</b> {companyDetails.data?.companyActivity}
      </p>
      <p className="text1">
        <b>Правна форма:</b> {companyDetails.data?.companyType}
      </p>
      <p className="text1">
        <b>Седалище и адрес:</b> {companyDetails.data?.companyAddress}
      </p> */}
    </Box>
  )
}
